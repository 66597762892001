// section Top Bar Slide
const TopBar = [
  {
    id: 1,
    title: "Get the best deals from local businesses",
    img: "/hero/lokalai25.jpg",
    desc: "Writing Essentials for the New Year",
  },

  {
    id: 2,
    title: "Artificial Intelligent",
    img: "https://misti.mit.edu/sites/default/files/styles/hero_home_desktop/public/2022-03/human-like-robot-and-artificial-intelligence-2022-01-06-00-25-53-utc.jpg?h=67494091&itok=C7js1A7t",
    desc: "Mako is an artificial intelligence, which combines computer science and robust datasets, to enable problem-solvingt",
    location: "Hamilton",
    shop: "Quincy Melbourne",
    shopImg:
      "https://images.luxuryescapes.com/fl_progressive,q_auto:eco,c_scale,h_75,dpr_2.0/hhsdvxgwggmyzti5fs1p",
  },
  {
    id: 3,
    title: "Popular Rewards Card",
    img: "/hero/slide2.webp",
    desc: "Lokalai is a customer loyalty platform that allows you to easily set up a digital loyalty card",
    location: "Wellington",
    shop: "Get Your Guide",
    shopImg:
      "https://www.discoup.com/x_Upload/Files/3238getyourguide345230.png",
  },
  {
    id: 4,
    title: "Online Coupons Booking",
    img: "https://d2dfxqxblmblx4.cloudfront.net/images/hero/branded-app.jpg",
    desc: "Online bookings with saving up to 50% off for activities and attractions",
    location: "Dunedin",
    shop: "Capelli Hair Studio",
    shopImg:
      "https://capellihairdesign.co.nz/wp-content/uploads/2015/04/2015-Capelli-Logo.png",
  },
  {
    id: 5,
    title: "Online Food Ordering",
    img: "https://www.gloriafood.com/wp-content/uploads/2013/12/featured-image-order-taking-system.png",
    desc: "Online Food Ordering and payment by scan QR code using for restaurants",
    location: "Palmerston North",
    shop: "Study Plex",
    shopImg: "https://studyplex.org/wp-content/uploads/2023/01/study.webp",
  },
];

// Section Best Deal
const bestdeal = [
  {
    id: "1",
    title: "21 Morgans Valley",
    location: "21 Morgans Valley, Heathcote Valley, Christchurch City",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://s.oneroof.co.nz/image/45/d0/45d00d0081a4fd77a249ee98aee021d4.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/48/5d/485d1fe494d82c52b110970f118e376c.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/3a/0a/3a0a68c88a8f44cfc5ce87a26d523605.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/f7/0b/f70bbf9164d352a965c5b31d2aa7d80e.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/8c/24/8c24a9f2fbb450de4724ddfd7a3bcd65.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/d4/1c/d41c56e1ca6d54cf61e38e061f39439f.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/1e/59/1e59bdd715bcbe5865cbe48db32379aa.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/61/e2/61e2669641ec1dda7c182b5d56b60fd2.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/6e/7d/6e7df918fa2c42a0557271cec3c746c4.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
      "https://s.oneroof.co.nz/image/42/11/4211ef3fbdfe271111f503c5643311b2.jpg?x-oss-process=image/quality,q_80/resize,w_2400/format,webp",
    ],
    videos: [
      "QCTbGr3NM2M",
      "p3c84y_2xvk",
      "Rdkf2Jyl0_8",
      "ddPUy-fvPOk",
      "cDFWDCizW8I",
    ],
    deal: "Auction",
    views: "100k",
    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "2",
    title: "The Original Grade 5 Kaituna",
    location: "Kaituna River",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://kaitunacascades.co.nz/wp-content/uploads/2018/08/Kaituna-Cascades-Rotorua-Rafting-New-Zealand-Waterfall.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2018/08/Kaituna-Cascades-New-Zealand-Waterfalls.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2018/08/White-Water-Rafting-with-Kaituna-Cascades-1-2.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2018/08/Waterfalls-Kaituna-River-Rotoua-1.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2019/01/FFP_130131-108991MEDIUM.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2019/01/Mud-Baths-2-1.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2019/01/FFP_130131-108712MEDIUM.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2019/01/FFP_130131-109006-MEDIUM.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2019/01/HG-Mud-Couple-scaled.jpg",
      "https://kaitunacascades.co.nz/wp-content/uploads/2019/06/Rotorua-hot-pools.jpg",
    ],
    videos: [
      "kqTNYGTZ4ME",
      "T76S51XuyWs",
      "UC3pgbsS6J8",
      "Re3jYKDw4I8",
      "pF0d-P-SlKo",
    ],
    deal: "family $80",
    views: "120k",
    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "3",
    title: "Ye-Chon Korean BBQ & Restaurant ",
    location: "95 Riccarton Road, Riccarton, Christchurch 8011",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/300559866_440524908100602_1819102524418243092_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=e3f864&_nc_ohc=3cu8bTBf51oAX9oIr30&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDZzNTDtHM7j9pv_VBsXGxgPDo5RSTTo3SiQoLvsr_WAQ&oe=64196F2D",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/128941640_106333858000494_2058097941929360099_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8bfeb9&_nc_aid=0&_nc_ohc=tCpXhOVsh9AAX_et3sO&_nc_ht=scontent.fchc2-1.fna&oh=00_AfA0B-YLcjZww6fVZxp5y_kI0PPUZxmmXprx2RH6ytaWAQ&oe=643B2528",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/128975628_106333801333833_120640413484963939_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=e-vjvHVDANEAX-aJLlG&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBbzJzLya6IxGX0OVenNPvsWHl-8TQye9kkPrzTEdls9Q&oe=643B25B6",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/128143299_106333874667159_1064456496914364914_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=Ih67qLEUPMsAX8qEPkt&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCR_6ffqjG0oahfe5vwtvK6vqNdbel-lbIb-2FJBQiffA&oe=643B08ED",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/300367531_440524911433935_2638085641029701317_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=DJpBDTaIc-EAX_tCkII&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAqVnKAO3xgwZUfVtWCqH1tdeOr5Jvk5DcvvYFfqaaWLw&oe=641987EC",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/128630278_106333838000496_1168657103547027821_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8bfeb9&_nc_aid=0&_nc_ohc=xNXRBzCG9oMAX-qPxv_&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDwyJnZoImguevVFmveUSk4THuvo5EWzhBbEkIEDi8SyQ&oe=643B1008",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/128762514_106333974667149_4914204339105285274_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=U1Uz7iZtCYsAX99H2gD&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDP8cn-RBRuskq7r6yc9DsSwYGziZQRm2WftT3I7MiFBw&oe=643AFCC7",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/128975628_106333801333833_120640413484963939_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=e-vjvHVDANEAX-aJLlG&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBbzJzLya6IxGX0OVenNPvsWHl-8TQye9kkPrzTEdls9Q&oe=643B25B6",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/128274338_106333818000498_7344996513111247094_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8bfeb9&_nc_aid=0&_nc_ohc=TfGtG9OoYEEAX-sHbhr&_nc_ht=scontent.fchc2-1.fna&oh=00_AfC-QDxAicSx55KaZ5VixED6nGRln5Plo-z_nZ88GqPwwQ&oe=643B13F5",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/128630278_106333838000496_1168657103547027821_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8bfeb9&_nc_aid=0&_nc_ohc=xNXRBzCG9oMAX-qPxv_&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDwyJnZoImguevVFmveUSk4THuvo5EWzhBbEkIEDi8SyQ&oe=643B1008",
    ],
    videos: [
      "YnZflQX_qMs",
      "rq-nNfUrgNc",
      "6zaoxxsRfEo",
      "DeN1s5gDdgI",
      "dRrkWLOfxp0",
    ],
    deal: "15% off",
    views: "10M",
    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "4",
    title: "Hair Removal Sessions with Consultation",
    location: "TAWA, Christchurch City",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://salonbe.co.nz/wp-content/uploads/2020/09/Blonde-Model-0027112-1024x608.jpg",
      "https://images.luxuryescapes.com/lux-group/image/upload/f_auto,fl_progressive,q_auto:best,c_fill,g_center,w_750,ar_16:9/0kbapwb7zofin2b0977h",
      "https://images.luxuryescapes.com/lux-group/image/upload/f_auto,fl_progressive,q_auto:best,c_fill,g_center,w_750,ar_16:9/ldhl7n0r66osxecjbt4",
      "https://images.luxuryescapes.com/lux-group/image/upload/f_auto,fl_progressive,q_auto:best,c_fill,g_center,w_750,ar_16:9/5t3xif1pkkl5yknig2s",
      "https://images.luxuryescapes.com/lux-group/image/upload/f_auto,fl_progressive,q_auto:best,c_fill,g_center,w_750,ar_16:9/0kbapwb7zofin2b0977h",
      "https://salonbe.co.nz/wp-content/uploads/2020/09/Model-1-Ashy-Glow-0029125.jpg",
      "https://salonbe.co.nz/wp-content/uploads/2020/09/Model-2-0029126-683x1024.jpg",
      "https://salonbe.co.nz/wp-content/uploads/2020/09/Amber-Model-Image-0027113-1024x683.jpg",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/288526350_567826991632163_6196867958932274632_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=730e14&_nc_ohc=xrvoujDaLGgAX9gIuT5&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCsBF5Zc3F31BALdH_tkwz0cPz1YNbLWcyaqQrEvYZ2eA&oe=641845D2",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/123288913_174351850979681_3863211342031447605_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=a26aad&_nc_ohc=a1b2RskZBNMAX8DnJeG&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBtueeKbEdozFzsab7cWjroPoRGRdAZtaIBdU2shhj6dw&oe=643B1885",
    ],
    videos: [
      "1fROzNkSQaQ",
      "JcJRsRzGT4E",
      "pcqPydpQXzA",
      "hhZwpZrr_0w",
      "kEenj8qeIEU",
    ],
    deal: "$39 combo",
    views: "2M",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "5",
    title: "Paradox Resort Phuket",
    location: "Phuket, Thailand",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/nofczq42jdwh5zr8zlxv.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/dce3hl2ozcz8xrtyn8ks.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/9o2r2bo5ripoky172s4.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/oprdr6ic7oilhylm7pq.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/vf40g4ugghdgb490389.webp",
      "https://image-tc.galaxy.tf/wijpeg-56goauah8cbj2lch0puombz9a/thumbnail-mvp-karon-helicam-photo-5.jpg?width=1920",
      "https://image-tc.galaxy.tf/wijpeg-5nocbawutgq28hnx686dnzojn/replace-websitelandingimage-1.jpg?width=1920",
      "https://image-tc.galaxy.tf/wijpeg-ay1cdl6p18sei0zrsouxfw3v7/pool-residence-paradox-web.jpg?width=1920",
      "https://image-tc.galaxy.tf/wijpeg-2ahw36x18rabuj7mq7zx124oo/poolbar_wide.jpg?crop=0%2C34%2C1685%2C948&width=1920",
      "https://image-tc.galaxy.tf/wijpeg-dupj1fp0lslume2bgntck4eyu/kinnaree-bar001_wide.jpg?crop=0%2C75%2C1511%2C850&width=1920",
    ],
    videos: [
      "0oeoAMAqwgI",
      "sj9I2zk0STc",
      "4k4BFg9w3ik",
      "GYo7Ew_E9m8",
      "JALbrXlsvvw",
    ],
    deal: "family $50",
    views: "200k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "6",
    title: "Vietnam: 10-Day Highlights Tour",
    location: "Vietnam",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/0q1e1c23t69efam3b9gf.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/y72pjfgrnbvzp545z7qs.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/glbe67zobbpkdkgkpm79.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/vcpkw6rprjzvz1w302y8.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/gytw84v588dlw0mvopm.webp",
      "https://cdn.inspiringvacations.com/eyJrZXkiOiI5MzdkZTU4MC1hOTNjLTQ2YWEtOTdmZS1jN2ZkYzFkZjY0MjcuanBnIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoxMDAzLCJoZWlnaHQiOjQ5MCwiZml0IjoiY292ZXIifX19",
      "https://images-api.intrepidgroup.travel/Intrepid/143543/8804203397150.jpg",
      "https://images-api.intrepidgroup.travel/Intrepid/143543/8808675868702.jpg",
      "https://worldjourneys.co.nz/wp-content/uploads/Country-Vietnam-2048x1457.jpg",
      "https://worldjourneys.co.nz/wp-content/uploads/World-Journeys-Essential-Vietnam-0-5147.jpg",
    ],
    videos: [
      "Z20pEmSdig0",
      "nBdrgGJsCJo",
      "nbQ_z-0ZO28",
      "peYUAVXo9UY",
      "ppfusm6vat0",
    ],
    views: "250k",
    deal: "30% off",
    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "7",
    title: "10-Minute Go-Karting Session",
    location: "Hampton Downs Road, Te Kauwhata, Waikato",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://images.squarespace-cdn.com/content/v1/5befd4c43c3a53612e1c70e9/1638367684704-E4CB2NZVCTWH6RLQU0XN/image0.jpeg?format=2500w",
      "https://www.fluxmagazine.com/wp-content/uploads/2017/02/Karting-Web-3.jpg",
      "https://cdn-4.motorsport.com/images/amp/YN1kXNP2/s6/go-karting-scene-1.jpg",
      "https://i.ytimg.com/vi/QfkLKpLZ-WQ/maxresdefault.jpg",
      "https://d2i4l4jrdru1k6.cloudfront.net/1280x720-132766-351883-ZadC2leEr2.jpg",
      "https://motorbaseperformance.co.uk/wp-content/uploads/2022/08/garford-tkm-festival.jpg",
      "https://i.ytimg.com/vi/OeNYZ3ilZ2A/maxresdefault.jpg",
      "https://motorbaseperformance.co.uk/wp-content/uploads/2022/08/garford-tkm-festival-2-980x653.jpg",
      "https://resources.stuff.co.nz/content/dam/images/1/w/3/p/q/e/image.related.StuffLandscapeSixteenByNine.1420x800.1w3pwk.png/1563751282288.jpg",
      "https://images.squarespace-cdn.com/content/v1/59a52a82e45a7c96f2f46283/1513269256198-QR1Y0T70Q6PJJ2I3LXTA/Freddie++Spindlow_5071.JPG?format=2500w",
    ],
    videos: [
      "kqTNYGTZ4ME",
      "T76S51XuyWs",
      "UC3pgbsS6J8",
      "Re3jYKDw4I8",
      "pF0d-P-SlKo",
    ],
    deal: "50% off",
    views: "540k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "8",
    title: "Full Day Hop on Hop Off Wine",
    location: "Nelson",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://bookmestatic.net.nz/bookme-product-images/products/8326/8326_image1_qec0XlpgGY_SXPQ_newphoto2.jpg",
      "https://bookmestatic.net.nz/bookme-product-images/products/8326/8326_image2_l9wOhBtDoH_SXPQ_newphoto1.jpg",
      "https://static.wixstatic.com/media/0daa22_1743207bc0bf47a380c52d9fab5ce21a~mv2_d_2048_1536_s_2.jpeg/v1/fill/w_1069,h_802,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0daa22_1743207bc0bf47a380c52d9fab5ce21a~mv2_d_2048_1536_s_2.jpeg",
      "https://static.wixstatic.com/media/0daa22_b5a023e4d17e43ff9d8a36fd7ff8265b~mv2.jpeg/v1/fill/w_975,h_802,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0daa22_b5a023e4d17e43ff9d8a36fd7ff8265b~mv2.jpeg",
      "https://static.wixstatic.com/media/0daa22_7e9ddcd9fde74233a38138ee8c862f69~mv2_d_5195_3463_s_4_2.jpg/v1/fill/w_1203,h_802,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0daa22_7e9ddcd9fde74233a38138ee8c862f69~mv2_d_5195_3463_s_4_2.jpg",
      "https://static.wixstatic.com/media/0daa22_d85fa7455cc8445592a8b52c3f12ab29~mv2.jpg/v1/fill/w_1903,h_970,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0daa22_d85fa7455cc8445592a8b52c3f12ab29~mv2.jpg",
      "https://static.wixstatic.com/media/0daa22_9558bf7a1788417ebbb754f1d1d14afd~mv2.jpg/v1/fill/w_1903,h_970,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0daa22_9558bf7a1788417ebbb754f1d1d14afd~mv2.jpg",
      "https://static.wixstatic.com/media/0daa22_9cfcedb6ae7549c193d2f4d6f0b6fa0f~mv2_d_3861_5784_s_4_2.jpg/v1/fill/w_476,h_670,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/0daa22_9cfcedb6ae7549c193d2f4d6f0b6fa0f~mv2_d_3861_5784_s_4_2.jpg",
      "https://static.wixstatic.com/media/0daa22_367601a9795c448baa1d18840148c625~mv2_d_5760_3840_s_4_2.jpg/v1/fill/w_1202,h_528,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0daa22_367601a9795c448baa1d18840148c625~mv2_d_5760_3840_s_4_2.jpg",
      "https://static.wixstatic.com/media/0daa22_12d04952d81d4515bc9ff99beba37e8a~mv2.jpg/v1/fill/w_1280,h_448,fp_0.50_0.50,q_85,enc_auto/0daa22_12d04952d81d4515bc9ff99beba37e8a~mv2.jpg",
    ],
    videos: [
      "UA6-_hP7e1o",
      "hxf8VAxefg8",
      "qUutVpbw5AU",
      "N3kJCRUGR4Q",
      "eyVWISN69qU",
    ],
    deal: "buy 2 get 1",
    views: "209k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "9",
    title: "Versailles Skip-the-Line Tour",
    location: "Paris, France",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Situated on an expansive 1,582sqm section at the foot of the Port Hills in a sheltered microclimate, this luxurious five bedroom, two-bathroom residence is a true retreat with exceptional views and expansive contemporary comfort.On the upper level, an exceptional kitchen with a large centre island, chef’s design, oversize gas range, and monochromatic styling provides an anchor for the large open plan dining and lounge area, as well as the cosy formal lounge area that is snugly comforted by a gas fire. With dual access to the sheltered deck, this is a space tailor-made for alfresco living and entertaining from noon until dusk, entranced by the mesmerising twinkling lights of the valley below. An additional living or rumpus room on the lower level has external access and presents an ideal retreat for teenagers or children.",
    price: 0,
    images: [
      "https://cdn.getyourguide.com/img/tour/a94deb3128a968da.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/d33c416367a191ce.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/588e9fcb75163928.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/e9e59bd5967c0e27.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/bd30074295e7759a.jpeg/145.jpg",
      "https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_1295,h_720/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/activities/f37zyax9jwxbjv79mc7z/VersaillesSkip-the-lineGuidedTour.webp",
      "https://cdn-imgix.headout.com/tour/11238/TOUR-IMAGE/e83af576-4211-4d7e-a482-3f49d922dbcf-6170-paris-versailles-skip-the-line-priority-access-with-audioguide-tour-01.jpg?fm=pjpg&auto=compress&w=1200&crop=faces&fit=min",
      "https://aws-tiqets-cdn.imgix.net/images/content/4852496036aa44f38064705ad0a66e67.jpeg?auto=format&fit=crop&ixlib=python-3.2.1&q=70&s=db52c1ab968bf857e341f088b12a9454",
      "https://d3ne5s9fv9p81l.cloudfront.net/ec/3a/de6/436/fc898f5cd74214cb2ab81e.jpg?imageView2/2/format/jpg",
      "https://cdn.getyourguide.com/img/tour/54be6f8abae4a.jpeg/99.jpg",
    ],
    videos: [
      "zdYeG4eZNpQ",
      "UeT_5q380h4",
      "7HkQczgDMp8",
      "194CDlsFpQA",
      "X235vpOToVU",
    ],
    deal: "buy 2 get 1",
    views: "310k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
];

const trendingdata = [
  {
    id: "1",
    title: "Royal Vietnamese Cafe & Restaurant",
    location: "8 Brake Street, Upper Riccarton, Christchurch 8041",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "+64 3 348 9381",
    desc: "A regal culinary treat that wouldn't be out of place at a royal banquet, Royal Vietnamese Cafe & Restaurant is bringing Southeast Asia to Riccarton with aplomb. Judging by its name, you might think Royal Vietnamese is a visual feast of crystals and plush velvet. Think again! This bright and roomy eatery enjoys a modern farmhouse vibe thanks to its arched ceiling supported by a network of wooden frames, dark oak furniture and the luscious vines that wind their way around the various wooden fixtures. A restful retreat from the city with heaps of charming countryside vibes, Royal Vietnamese is a breath of fresh air. Once there, make sure you leave plenty of time to peruse the menu. Hint: it leaves no players behind. Let's start with treasured greats that are loved all over the world like pho and other variations of noodle soup with fish cakes, crab, beef and chicken leg. Next are the stand-alone noodle dishes that are made doubly irresistible with fresh seafood, satay chicken, beef, tofu and mixed vegetables. Next come the chicken, beef, pork and seafood dishes, all of which are loaded with authentic Vietnamese flavours. We're talking creamy chicken curries, sizzling beef, sweet and sour pork, BBQ ribs, lemongrass squid, king prawn curry and much more. As for vegetarians, aromatic tofu curries and stir-fried vegetables form the crux of the delicious meat-free options. How's that for the Royal Vietnamese treatment?",
    price: 0,
    images: [
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/271603455_133392272490624_3944601248427409206_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=e3f864&_nc_ohc=cTBKpqu8t8gAX9JvWRU&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDArgd2dMb0fYKF9z9AxST2hE30PuiD0aUgAJvfQpRCrg&oe=641835E0",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/331328762_1430617714137049_6948311512171842965_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_ohc=bH9Kqy0lfW4AX9KJZDT&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCcc2Lh1fUS4NRKq_b0_K2YU6DM0xIxtmzlJOr0UuM47Q&oe=64178E87",
      "https://www.firsttable.co.nz/_next/image?url=https%3A%2F%2Fimages.firsttable.net%2F1292x800%2Fpublic%2Frestaurant%2Fa7b1c12fac%2F295577544_184818180681366_2797982161279142214_n.jpg&w=3840&q=40",
      "https://tb-static.uber.com/prod/image-proc/processed_images/7cccb76277688f45aa8e0be0e44c10e7/16bb0a3ab8ea98cfe8906135767f7bf4.jpeg",
      "https://images.myguide-cdn.com/md/christchurch/companies/co-ba-vietnamese-thai-restaurant/large/co-ba-vietnamese-thai-restaurant-858530.jpg",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/334446313_761526882032849_6333482417847551389_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=fdg2Wy2TxywAX-ZP2yY&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDBfdyD2TUbPUO5LcCv5X6x22TqtDmCYr3qko9s8Q7aoQ&oe=6418093F",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/333809982_1328877574625063_5570333700837790345_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_ohc=UArUci4OPn4AX9N3bhM&_nc_ht=scontent.fchc2-1.fna&oh=00_AfB3ybLGnmq7wlc6s5JsyKuQ8-grUdSx0buoi_nFs262oA&oe=6417FC0E",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/334238518_225003973258020_2736685742670323519_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_ohc=gkQk3v1zrtsAX8WTEO9&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBCm66bCShZf6AcmbS5z1XovrCFjns-JOa7J5QyWVmoQw&oe=64188727",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/332533088_5711602572281981_3789465180336369549_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=730e14&_nc_ohc=LZymBhgq3DgAX91inFR&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDlZgcYVepJeYX8x6Yo5ZXeYu76yz3idINZ8_Y7e-HJ4Q&oe=6418FA05",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/332480130_5810586409031903_3981121699793953663_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=730e14&_nc_ohc=lLVZxlknRWwAX-IHUlC&_nc_ht=scontent.fchc2-1.fna&oh=00_AfArTRqHp_nylt0OcjtNeDvPHeunNxp4FrorkvhegnPgXA&oe=64180C3E",
    ],
    videos: ["6fRxIt3HQ4g", "bOSklyvNP-o", "beeydZ6JqAo"],
    deal: "15% off",
    views: "130k",
    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "2",
    title: "Keo’s Tastes of Thailand",
    location:
      "    2/123 Victoria St, Christchurch Central, Christchurch 8013, New Zealand, Christchurch, New Zealand",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03-355 6229",
    website:
      "https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Fkeostasteofthailand%3Ffbclid%3DIwAR3NgB38E7iO3j9jMvHG7FvnhwIu29xcZEFAumAcFQdubkf2FuGa8gjhK1E&h=AT000VkMYluHNArNiR4aTCrb3kaCFiT7SlHip1IUcoNlDWfp0JKi4OArYHhGtXZ6KTYI9UKmnxwdp4Brt153dmNT3dHxxgwDvj6DUbcATU7e8Ib3Ve7TnsxSeTMhiDSdTMbh-9Bu3QUre7_G-Q",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "A DESTINATION. Whether for lunch, dinner or a late night snack, head to Keo’s Tastes of Thailand Restaurant and Bar. You will find Keo’s down the alley at 123 Victoria Street in Christchurch’s central city. With cream upholstered chairs, a touch of gold, a lot of mirrors, and a stunning giant crystal chandelier it’s not a typical Thai Restaurant. Keo’s is a perfect place to enjoy delicious food paired with a purposeful wine list, curated by Gerald from Cellarworks Wines to perfectly compliment Thai flavours. ",
    price: 0,
    images: [
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/284163720_120203444022011_1115721088967345118_n.jpg?stp=dst-jpg_p720x720&_nc_cat=100&ccb=1-7&_nc_sid=e3f864&_nc_ohc=C7beqsPBipUAX8UQ9_R&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDD2N5FqSxoCoiqo475fMJrwYJAPi0LjEWDII0gYaHuXQ&oe=6419349B",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/335135666_958177995176883_716867128942551194_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=730e14&_nc_ohc=k3EpPyN02hYAX-YFfr4&_nc_ht=scontent.fchc2-1.fna&oh=00_AfA0519y91GE4d1VfUsxVGcXEDIUChUPVkvGH5SGgQte5g&oe=64194F5B",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/335154695_1261778184550587_1034558778955293670_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_ohc=4u0AbcL5g7EAX_2-OPV&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCjzZankViWc5BYJWY7oML1EK9fsXr3yIOR2a60i3TW1g&oe=641965F3",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/334320851_504426468563408_232991175516525120_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=4zNr_IIwhDEAX-xtg2O&_nc_ht=scontent.fchc2-1.fna&oh=00_AfB4LS7Gb6KO-_Fqz6kGeHi2KLiLkp0VipgE7j9Gkb6KMg&oe=64186E89",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/333921657_1857325651290325_3634374368686846956_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_ohc=7JwBCnDtj6UAX8wqowA&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBPx0F3FNBtCFT2ZeQkwtPzlOMTfSX1Gu6nIE_K63fovQ&oe=6418979A",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/333987623_1195748137743300_5773580953836858720_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_ohc=nVsKMdz5v1EAX-l0dXW&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBPNkodkQR8xoAMA2fodSFqhuWTZL1JACca9U3_v060pQ&oe=6417A12D",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/332700096_1178493266136624_8484503540353131584_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=Z56t3RqPg-kAX87tm0m&_nc_ht=scontent.fchc2-1.fna&oh=00_AfADA3OtjlsBV-XFIjcruoTubKFARKvaKqoiLMaKTMGcaQ&oe=6417FFB1",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/332894145_871963800588619_2480360741946721659_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=730e14&_nc_ohc=MJFQEhI9CUMAX-NfiBB&_nc_oc=AQlWbA5xF5KtJ3CFd3ElbfuVbKge96BL3USIXj5HsOYvZ31WF8Ujks7NFBaryFnVLlfAa3gEdg_kFGSKF618_77U&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAgmFCMPfCiYqrrIIOaBVT2oQXFgM9x_UjVC3lJWZGYrQ&oe=64183790",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/332547189_722063762720657_5115513390479588405_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=730e14&_nc_ohc=coYihMIQwFwAX_7xL6E&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAZvjoShPBnZ5-dGajlXIFGo1Fd8-wfLQ99mr6emFvrLw&oe=6418AEE9",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/331049622_883360089582567_5083744068536197576_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=730e14&_nc_ohc=Jd_jEq0HecMAX9xcwTK&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCMZfYvZ5lpQwoauVRAKRMxWJYlio7PMmOVt_E-gcSbEw&oe=6417A96E",
    ],
    videos: [
      "YHgaGwhVffo",
      "F86GfZIph8o",
      "6B9Zcb4MdAg",
      "o2l59pRPT8U",
      "HXp86cz3Ix0",
    ],
    deal: "buy 1 get 1",
    views: "220k",
    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "3",
    title: "Ferrymead Golf and Leisure Park ",
    location: "100 Ferrymead Park Drive, Christchurch, New Zealand",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03-376 5350",
    website:
      "https://l.facebook.com/l.php?u=http%3A%2F%2Fwww.ferrymeadgolf.co.nz%2F%3Ffbclid%3DIwAR2JLee4_RJ17YYlzVACH1ViiEEyuVrXp7c9mbYpI4UtQnk5EKoRY0lgpYA&h=AT1ej43-bCWvaipXB4Ym_H7HvgFFe7PLm3ceW0dkAAKp3HFjuqXb4ITUsNgNWhtjtn75nRNYy-prgiekK6fr1J0xmck_Mac2J0O1BSdoAmivOLQhN8nscROOqvLkUIJ8bpNKDuEWBhNwdQygRKE",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Ferrymead Golf and Leisure Park is Christchurch’s premier family fun centre. With so much to do in one beautifully scenic venue, you’ll be coming back to visit time and time again!. Ferrymead Golf has something for everyone – whether it be a spot of golf on our public (green fee players welcome 7 days a week) links-style 9 hole golf course, which is made up of 7 par 3s and 2 par 4s, with 2 different pin placements on each green, testing your skill on not one, not two, but three challenging, differently themed Mini Putt courses, or seeing how far you can hit the ball in our 26 bay, all weather Driving Range.there’s something to please the experienced or budding golfer in any family. But the fun doesn’t end with clubs and balls! Get a team together and step into the arena on the urban themed field of Ferrymead Paintball. Then relax in the fully licensed cafe and bar, serving delicious drinks and food. Birthdays, Stags/Hens dos, work functions and weddings are all catered for with a range of affordable packages to make your party a memorable one ",
    price: 0,
    images: [
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t31.18172-8/467754_10150635379989983_2055467997_o.jpg?_nc_cat=105&ccb=1-7&_nc_sid=e3f864&_nc_ohc=Zmo7KUXXJbsAX-3DDRx&_nc_ht=scontent.fchc2-1.fna&oh=00_AfB5hFxrvEdebqE2KqdJBFgSV82urWk0IVC8sk8nY_U8wQ&oe=643AE927",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.18169-9/408432_10150635425629983_2105374375_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=e3f864&_nc_ohc=oRJI7VpLu2MAX_3P2Yh&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDYRqkWA23raEBJZ0BjtqLXuskL6-k3rKsoCZH33So40Q&oe=643B121E",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/334734078_1013069099663995_3337442569220377186_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_ohc=gBKrDM4QV74AX9Cmxew&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBUIQvti8ITA3qQsI5n3vbacY1wraI-PHS54cC46XS-aQ&oe=6417A8D8",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/335149762_149449181331384_2905659592900609881_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_ohc=RNuXw5IyJNoAX_UDe2-&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDajglXk1Ucy-cA8K5qO9tvKAgShLut2s5l1MlnBW-RUA&oe=641784F8",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/335248246_600262181973760_6587018197406247771_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=730e14&_nc_ohc=mX1LtZRnxikAX-dH5qL&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAJFUiWsYi2LWEmgu0AzLTjM4VLiQmii1GTDzzayjq-pQ&oe=64194E41",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/327187268_778662226576893_518675803713608302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=730e14&_nc_ohc=HF4Frw-TmBsAX_K2jkC&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCJZGxU8i2gO8iXCB0oKe6PoA5RRFY7G2E_dub0SwfwtA&oe=64179634",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/329251950_8786727434702777_2154226417573412315_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_ohc=qCY1ZvklJHgAX_Y4urC&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDyndypqEgg1UOHmNNB2OQre7Ez0E0fjUxl01cZAlpSew&oe=6418DA5A",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/329420985_1622560738179457_6044625685554541381_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_ohc=64YpJX03RWQAX_AHJpn&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAsnWvK8X3LAaJd7yvykB1eEUmg6N0ehhtQCFHdfGnX2A&oe=64190144",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/314970314_503626055112945_7373722193402044110_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_ohc=Lo51bXhi55oAX9bSVSk&_nc_ht=scontent.fchc2-1.fna&oh=00_AfC7pJBXDZ_plqRvugRLZ45AYtnMjNJ-yxvHrJ4pIJPS2g&oe=6417C08B",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/313897434_503626308446253_6459543784794690304_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=czmqxZVZWgQAX-LFhmo&_nc_ht=scontent.fchc2-1.fna&oh=00_AfB_JpeWOohZrWIocQQStSqWr0yHXLguX79UlxlGQjuvpg&oe=6417F594",
    ],
    videos: [
      "T6d49QKX8qQ",
      "rqr08hP7G5g",
      "QB6_srnDqvA",
      "BrV3Cje0goo",
      "9rCe42xKnAU",
    ],
    deal: "$39 combo",
    views: "12M",
    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "4",
    title: "Caddyshack City Indoor Mini Golf",
    location: "21 King Edward Terrace, Woolston, Christchurch 8023",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 384 1566",
    website: "https://www.caddyshack.co.nz/",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Ultimate Miniputt Experience For Kids Parties, Birthday Parties Group Events. Social clubs , Team building , Friend get togethers , Canapes , Espresso coffee, Great fun. Email us for information. Make A Booking. Call Us Now. Destinations: Christchurch, Canterbury.",
    price: 0,
    images: [
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/300569667_3280888972228480_5762323093090948289_n.jpg?stp=cp6_dst-jpg_p720x720&_nc_cat=109&ccb=1-7&_nc_sid=e3f864&_nc_ohc=WEmBnZLhGV0AX-gIppY&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDxZTCYaOtVTJrbpABUy1UuvxxAkl2EwBW6lUJiQdHd5Q&oe=6418ACF9",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/304937968_447029884146278_4730059790444613353_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=19026a&_nc_ohc=Pjn32BKhdLAAX_-sjTJ&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDYqHyG09yt0WJZ0bAqVHrqnsJqyYeW4akxSqvZxNTqZQ&oe=6418DDEB",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/319673259_1388418511984422_9116112033460313674_n.jpg?stp=cp6_dst-jpg&_nc_cat=106&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=I2nZQ4RpexcAX9HjUl8&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBFwZ6H1rzsgV0UrHkdLL0d6qhGyqEz6LWD_GTjHHT5QQ&oe=64187982",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/320878865_2842791935865813_3702706490778777368_n.jpg?stp=cp6_dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=yYfP78ewwcAAX8eT_1J&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCty5RXP7gW-rW9iAgJrporcHTlPQdPntZ7J-f268fuLw&oe=641870B2",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/320010451_730364212053741_2891365873783471685_n.jpg?stp=cp6_dst-jpg&_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=HVgHp5LsjacAX_VPfQi&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBakJ2rmpaR0DPbfUMZRv7q8lUoxVq50sRJxIP7EP506Q&oe=64183A91",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/317835125_514869367362329_3446121754194689643_n.jpg?stp=cp6_dst-jpg&_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=T7qgpbRWGacAX9lxBFc&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBzCDetHdi9Qw_LjTChe43QyUYxHqmHm1eIICQCLbBgLQ&oe=64185D37",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/317360637_514869357362330_4890998538450492849_n.jpg?stp=cp6_dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=quqPZ49yPzQAX-x2l3W&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBGJVZB1WpKWparyMgaaDygPU7sIihcBFa1eyYK7MZmxg&oe=64187BA0",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/314471886_493646056151327_221595361514675554_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=730e14&_nc_ohc=Re3phHuu4uEAX9ODp_K&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCLDbV1qWDyS6bdJmfzTMrmq5UEkt9eAjRScbo-iqN7IQ&oe=6417FEF3",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/313441893_493644369484829_4371635294715813176_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_ohc=SFnTCZ186ZgAX_EymK8&_nc_ht=scontent.fchc2-1.fna&oh=00_AfD-Kh4uBKDCZHg1F_LRHI75gECq0nkHvzxdYXeiJ6K9bA&oe=64192B94",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/313426915_488747216641211_2532259987562616245_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=730e14&_nc_ohc=l2uj7PAQ9pIAX-D4Gh4&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCsiiIJd44L9vf7ABRQ11DxS13tjE2-Wpqv03bcjbCssA&oe=64187DFF",
    ],
    videos: [
      "jJqEOFmqm-0",
      "F6OPBherK88",
      "9swIWQtemgk",
      "_fAoXFEKQyc",
      "rRxOBGkAdsg",
    ],
    deal: "family $50",
    views: "512k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "5",
    title: "8 Days Vietnam Highlights Tour From Hanoi",
    location: "Hanoi, Vietnam",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "+84-989291913",
    website:
      "https://vietasiatravel.com/tour/8-days-vietnam-highlights-tour-from-hanoi-ct464.html",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Adventure through Vietnam on a highlights tour to experience the beauty, history, wonder, and grandeur of the country. On this custom tailored Best 8 Day Vietnam itinerary, you will discover the breathtaking scenery of Halong Bay and the charismatic floating lanterns of Hoi An. From Saigon to Hanoi, the labyrinth of tunnels at Cu Chi, the diversity of Vietnam is yours to enjoy, while the marvel is yours to explore. Hereunder is the suggestion how to spend 8 days in Vietnam.",
    price: 0,
    images: [
      "https://vietasiatravel.com/files/thumb/1600/500//uploads//Banners/Mu-Cang-Chai.jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/Du-lich-Danang-Hoian-(9).jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/advice-vaccines-vietnam.jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/vietnam_halong_bay_scenery_inside_the_bay-e.jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/Hochiminh-mausoleum.jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/halong-rowing-boat-4.jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/Overview-1.jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/vietnam_unidentified_pedicab_and_tourists_on_the_streer_of_hoi_an_ancient_city-e.jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/advice-vaccines-vietnam.jpg",
      "https://vietasiatravel.com/files/thumb/750/500//uploads//VietNam/Anh-tour-720x480/Du-lich-Danang-Hoian-(15).jpg",
    ],
    videos: [
      "uvNskCGTPmE",
      "ppfusm6vat0",
      "u9pi7nFtfNo",
      "nBdrgGJsCJo",
      "zxMCl_j2HCo",
    ],
    deal: "20% off",
    views: "230k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "6",
    title: "Private Tour of Laos Overview 6 Days",
    location: "Lao",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    website: "https://www.tourradar.com/t/76115#p=3_",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Laos is home to smiling faces, stunning river scenery and delicious cuisine. In purpose to bring you a view of Laos at glance, Legend Travel Group  would like to introduce to you the “Laos Overview 6 Days/5 Nights”. The experience you’ve got is discovering Vientiane’s atmospheric temples and riverside eateries, a beautiful Luang Prabang, which reveals elaborate monasteries, colorful markets and a delightful blend of architecture. Mingle with the locals on a visit to a family home for a special Lao blessing; see hundreds of robed monks collecting morning alms, as you discover the true heart of Laos.",
    price: 0,
    images: [
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_a02a580e.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_85817b0c.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_f9e40026.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_f8419016.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_08b78c0c.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_be872e13.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_3ba68298.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_e51feb6f.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_61997d3b.jpg",
      "https://cdn.tourradar.com/s3/tour/1500x800/76115_e25a9e81.jpg",
    ],
    videos: [
      "uV4g-DPdw6M",
      "Zsx9FhogG9g",
      "zywGdN6gpb8",
      "tSew2CUvT7U",
      "qymCqr_9w84",
    ],
    views: "250k",
    deal: "30% off",
    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "7",
    title: "Dusit Thani Laguna Singapore",
    location: "Singapore, Singapore",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    website:
      "https://luxuryescapes.com/nz/offer/dusit-thani-laguna-singapore/0062y00000DfuiLAAR",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Occupying an enviable location within minutes of Singapore Changi Airport, Dusit Thani Laguna Singapore is the perfect place to luxuriate, indulge and discover the sights of the city. Framed by sweeping emerald golf greens, this shining jewel is exemplary of Singapore's reputation as the 'Garden City'. Energise yourself with a friendly match on the tennis courts, perfect your swing at the driving range, or relax with a premium spa treatment at Devarana Wellness. Take a global tastebud tour of multi-ethnic cuisine at the forest-themed Greenhouse restaurant, or unwind with a smooth scotch and light bites at Legends Bar. With a complimentary shuttle to the airport and selected MRT stations, the sights of Singapore are within easy reach. Shop 'til you drop at the city's glittering malls, head to the beach for a day of surf and sun, or stay in and spend the day simply floating contentedly in the swimming pool.",
    price: 0,
    images: [
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/x1tb0cf8tu9k6n3pt0m.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/jmrqvgiko86ydupjs1gz.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/xyuth1kcouji7d7ejugb.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/kh4dcet9bu2bcc3x82oj.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/c11gnf6jpi6ykoqae5f.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/t50k4mry9ov0oa2wv066.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/mr16io7guiqa07o64nck.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/wn1juwh8nlpelfdclumh.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/40j9d6aidwtkcjp5hlus.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/8wbbmwjajw81894kv1ih.webp",
    ],
    videos: [
      "L8sPZIkKywA",
      "8nyH9-1Tbwc",
      "SjcOTjslp4w",
      "DhgMk8_1Z9k",
      "9gCZlyPCt7I",
    ],
    deal: "30% off",
    views: "640k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "8",
    title: "Craft Beer Tour Manchester!",
    location: "61 Mosley Street, Manchester",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "EXPERIENCE THE BEST CRAFT BEERS MADE IN MANCHESTER. Our craft beer tours operate every Friday and Saturday afternoon at 1:30pm. One of our friendly guides will take craft beer lovers on an unforgettable journey through Manchester, introducing you to local experts who will offer a fascinating insight into their produce. Private tours can also be arranged on request, please contact us for more information.Each tour lasts around three hours, beginning and ending in Manchester city centre. We visit both craft beer bars and breweries, sampling a variety of locally produced beers. Our venues are not always open to the public but with our unique tour, you will get to enjoy an unrivalled look at the exciting Manchester beer scene, and most importantly of all, taste its fantastic range! Our tours will operate in any conditions so please dress appropriately for the notorious Manchester weather. Please be aware that we walk between venues, although it is mainly flat and not excessively taxing ",
    price: 0,
    images: [
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/IMG-20210806-WA0019.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/IMG-20210915-WA0005.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/DSC_0425.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/IMG-20190126-WA0009.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/DSC_0544.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/DSC_0576.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/IMG-20191012-WA0004.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/DSC_0417.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/DSC_0422.jpg",
      "https://craftbeertourmanchester.co.uk/wp-content/gallery/dec-19-gallery/DSC_0526.jpg",
    ],
    videos: [
      "4dszlbDFaFM",
      "0TcUYGu6yW4",
      "e9JpCuDapzk",
      "ZGb5Nb-CC48",
      "qoGODlwL9XA",
    ],
    deal: "buy 2 get 1",
    views: "209k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
  {
    id: "9",
    title: "Sportsfuel Supplements",
    location: "789 , Te Rapa Rd, Te Rapa, Hamilton, 3200",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    website: "https://www.sportsfuel.co.nz/",
    openhome: "OpenHome 10 Mar 2023 12:00",
    desc: "Sportsfuel Supplements began operating in 2006 and has quickly become New Zealand's 1st Choice Supplement Store. We provide high quality, top brand supplements at super low prices, delivered directly to your door. We manage the shipping and delivery process to ensure your order is received as quickly as possible.",
    price: 0,
    images: [
      "https://d12agcgpij2qxn.cloudfront.net/product/2021/12/product-1640307103-473.jpg?v=1",
      "https://images.prismic.io/sportsfuel/37c0a657-17bc-4991-ac9a-cb3a6a6cae4b_SHOP+SF+ACTIVE+%282%29.jpg?auto=compress,format&w=1280",
      "https://scontent-syd2-1.cdninstagram.com/v/t51.2885-15/335601389_183043154474307_671012408446774401_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=MlEWw_WA0DQAX_IMExM&_nc_ht=scontent-syd2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfApwRcgM0YreBXAW13KTMv3R8qmlg_l3uDuNbzabjxOew&oe=64194350",
      "https://scontent-syd2-1.cdninstagram.com/v/t51.2885-15/330833093_176668391776294_7648820014924912056_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Saf3SSyEhbcAX_O1Apv&_nc_ht=scontent-syd2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAPgxGBC7C_aVzVassKS_7fGuEo3FXE67JRso_pJOsgeA&oe=64188060",
      "https://scontent-syd2-1.cdninstagram.com/v/t51.2885-15/333882240_1523352374839736_5702340335120322000_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=WBZC5Z-GpSQAX-bO9sD&_nc_ht=scontent-syd2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBwPMeVsd1c8OohYGtoGN0ISXzCUHoDrMYu9qSXhEW2pg&oe=6419521B",
      "https://scontent-syd2-1.cdninstagram.com/v/t51.2885-15/333882240_1523352374839736_5702340335120322000_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=WBZC5Z-GpSQAX-bO9sD&_nc_ht=scontent-syd2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBwPMeVsd1c8OohYGtoGN0ISXzCUHoDrMYu9qSXhEW2pg&oe=6419521B",
      "https://scontent-syd2-1.cdninstagram.com/v/t51.2885-15/336013355_967033887618417_3197428496020748245_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=cblJ-Dw0aAcAX9Q6Jlz&_nc_ht=scontent-syd2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfB6uV5lpv_42Gezj_NtLo2GrSew31ZdVujZlfWWW-gKpQ&oe=6418C6B0",
      "https://scontent-syd2-1.cdninstagram.com/v/t51.2885-15/334553943_690585462752351_3873830485526285984_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=qivIGKLCta4AX8RdD2S&_nc_ht=scontent-syd2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBJFk91uzINVxG_u1I9mShMOwQi3goYPfDgSncbO0h5wA&oe=64196AE3",
      "https://scontent-syd2-1.cdninstagram.com/v/t51.2885-15/330833093_176668391776294_7648820014924912056_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Saf3SSyEhbcAX_O1Apv&_nc_ht=scontent-syd2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAPgxGBC7C_aVzVassKS_7fGuEo3FXE67JRso_pJOsgeA&oe=64188060",
      "https://scontent-syd2-1.cdninstagram.com/v/t51.2885-15/334786534_1322877858270924_5956849327028238725_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=iGstsTKZrowAX9Naawj&_nc_ht=scontent-syd2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAH6wgYGjBAB68XkI2noIqLDoYo9MwLYFtu9b3040uSjg&oe=6417BBB6",
    ],
    videos: [
      "bDwkEwtKrr0",
      "QpFuOJ3r3FY",
      "4BIcvQT13KQ",
      "7pf4rVI0UGw",
      "6n9QFJrsuLo",
    ],
    deal: "Afterpay",
    views: "310k",

    garage: 2,
    room: 5,
    bath: 2,
    broker: "Adam Heazlewood",
  },
];

const cannotmissdata = [
  {
    id: 1,
    title: "Daily Wine, Beer & Gin Tours",
    desc: "Our daily wine, beer & gin tours have been designed exclusively for adults only with a small group of up to 11 imbibe lovers offering complete relaxation and a magical experience in the Central Otago region.",
    location: "Central Otago region",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    auction: "Auction 30 Mar 2023 12:00",
    img: "https://img.trvcdn.net/https://media-cdn.tripadvisor.com/media/photo-w/1c/52/18/10/valley-of-the-vines-lunch.jpg?imgeng=m_box/w_1418/h_946",
    images: [
      "https://images.campermate.com/photos/c676991857d3c3c71b9465a5b7584738-WxH.jpg?fit=cover&width=1200&height=800",
      "https://img.trvcdn.net/https://media-cdn.tripadvisor.com/media/photo-w/1c/52/18/10/valley-of-the-vines-lunch.jpg?imgeng=m_box/w_1418/h_946",
      "https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,h_933,q_75,w_1400/v1/clients/queenstownnz/couple_in_vines_wine_tasting_appellation_wine_tours_a5a4367e-f2c2-488e-9e5a-0dcfcfe05086.jpg",
      "https://myqueenstowndiary.com/wp-content/uploads/2021/05/DSC_0721.jpg",
      "https://assets.travelloapp.com/uploads/deal/boutique-queenstown-wine-tour-deals.jpg",
      "https://centralotagonz.dottie.io/assets/Tourism-Operators/images/helijeepwine30_MG_1798__Resampled.jpg",
      "https://myblondebucketlist.com/wp-content/uploads/2023/03/wine-tours-queenstown-new-zealand-1.jpeg",
      "https://myblondebucketlist.com/wp-content/uploads/2023/03/wine-tours-queenstown-new-zealand-1.jpeg",
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0e/8f/74/6b/enjoy-a-delicious-platter.jpg?w=1200&h=-1&s=1",
      "https://winecountrytable.com/wp-content/uploads/2017/07/2017-6-14-Domaine-Carneros-Napa-Wineries-Wine-and-Cheese-Pairing-Blog-Size-0861-1600x950.jpg",
    ],
    videos: [
      "3tWxI2mScrI",
      "TFh3s-FS_5A",
      "pVjialGlCc4",
      "V4SPIzulVfw",
      "dYZA_nmeWEE",
    ],
    deal: "buy 2 get 1",
    views: "310k",
    shop: "Alpine Wine Tours",
    shopImg:
      "https://alpinewinetours.co.nz/wp-content/uploads/2019/05/alpine_wine_logo_2019.png",
    follower: "250k",
  },
  {
    id: 2,
    title: "Vatican Museums",
    img: "https://cdn.getyourguide.com/img/location/5b211711d37bf.jpeg/99.webp",
    desc: "Enter a world of Renaissance art and intrigue on a tour of the Vatican Museums, Sistine Chapel, and St. Peter's Basilica. Using the skip-the-line entrance, avoid the queues and see works by Michelangelo, Raphael, and Leonardo da Vinci. Meet your guide and bypass the line with your skip-the-line ticket. Explore the collection of the Vatican Museums, hearing the history that dates back to the 16th Century and the work of Pope Giulio II. Explore 4.35 miles (7km) of priceless art. Discover Greek Classical art and the works of Renaissance masters, Michelangelo and Raphael. See the statue of Laocoon and His Sons and the Transfiguration, and admire the works of other Italian masters such as Leonardo da Vinci, Perugino, and Fra Angelico. Hear the history of the Catholic Church and continue to the Sistine Chapel, home of Michelangelo's The Last Judgement. Step inside St. Peter's Basilica to gaze at La Pieta. Finally, visit the paper crypt, where Pope John Paul II and others were laid to rest.",
    location: "Rome: Vatican",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    website:
      "https://www.getyourguide.com/vatican-museums-l2738/vatican-highlights-and-sistine-chapel-skip-the-line-tour-t405500/",
    images: [
      "https://cdn.getyourguide.com/img/tour/5c41cf24a8859.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/5c41ce65bfe20.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/5c41cdff77aff.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/5c41ce480e5b0.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/5c41ceb037e81.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/5c41ce9f8ef17.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/639c94fb33ea0.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/6262ddd6dfe52.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/639c951e27370.jpeg/145.jpg",
      "https://cdn.getyourguide.com/img/tour/6262de2ea9fde.jpeg/145.jpg",
    ],
    videos: [
      "NeTNusuDBg4",
      "RiwWR4QwcwM",
      "mQhlFL0Vrvw",
      "5hDliwRKlm0",
      "gKwgO9IRnEg",
    ],
    deal: "20% off",
    views: "650k",
    shop: "Get Your Guide",
    shopImg:
      "https://www.discoup.com/x_Upload/Files/3238getyourguide345230.png",
    follower: "20k",
  },
  {
    id: 3,
    title: "Quincy Hotel Melbourne",
    desc: "Experience a feast for the senses in Quincy's three dining venues. Enjoy overnight accommodation in a vibrant, laneway inspired room plus breakfast for two from Salted Egg's mouth-watering a la carte menu. Quintessentially Quincy also includes your choice of two coffees or bubble teas' from SingSong and two Quincy Slings in The Q rooftop bar, ensuring your tastebuds are satified from check-in to check-out.",
    location: "509 Flinders Lane, Melbourne, VIC, 3000",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    website: "https://www.quincy-hotels.com/en/deals/Quintessentially-Quincy",
    img: "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/yz3k9b4guws6ra3alfbp.webp",

    images: [
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QMel/Banner/Desktop---Hotel-Detail-Masthead2x.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QMel/Room2/QMEL_Caledonian_Masthead1_Image.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QMel/Room1/QMEL_Celestial_Masthead1_Image.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QMel/Room3/QMEL_HosierClubRoom_Masthead1_Image.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Deals/Quintessentially-Quincy/QMEL_Deal_QuintessentiallyQuincy_Masthead_Image.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QH/Banner/Desktop---Hotel-Detail-Masthead-2.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QH/Room1/Desktop---Residence-Detail-Masthead.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QH/Room1/Desktop---Residence-Detail-Masthead---2.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QH/Room3/Desktop---Residence-Detail-Masthead---4.jpg",
      "https://www.quincy-hotels.com/-/media/StayFarEast/Images/QuincyHotel/Hotels/QH/Room3/Desktop---Residence-Detail-Masthead---2.jpg",
    ],
    videos: [
      "8N5W2qe93_k",
      "onz0A40f18s",
      "s96Akr8MWX0",
      "te9CYKo62fU",
      "KB2MqKDee_g",
    ],
    deal: "30% off",
    views: "895k",

    shop: "Quincy Melbourne",
    shopImg:
      "https://images.luxuryescapes.com/fl_progressive,q_auto:eco,c_scale,h_75,dpr_2.0/hhsdvxgwggmyzti5fs1p",
    follower: "51k",
  },
  {
    id: 4,
    title: "Hair Cut & Style",
    desc: "Boutique Salon in the heart of Parklands",
    location: "32 Valecrest Avenue , Christchurch, New Zealand",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "021 130 4637",
    img: "https://images.pexels.com/photos/3065209/pexels-photo-3065209.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    images: [
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/299375411_476383484492748_7016329188006770394_n.jpg?stp=dst-jpg_s960x960&_nc_cat=106&ccb=1-7&_nc_sid=e3f864&_nc_ohc=uW3bhsaYL4oAX8qqPUv&_nc_ht=scontent.fchc2-1.fna&oh=00_AfB4kOgMXfUK2F-zrIaNdhNMGOHyaXj14M8Wf4DGlDhjqQ&oe=64196F1C",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/43765957_1170193789795450_6238627507122208768_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=e3f864&_nc_ohc=2hVZZhK-iOkAX-e4SDu&_nc_ht=scontent.fchc2-1.fna&oh=00_AfD9Zjk4r_U3JmPpveZW0hv2Nbpxs43NHfYMy0fMM4Vy7w&oe=643B6119",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/43581313_1169510343197128_5219549243726888960_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=e3f864&_nc_ohc=9Bl5csWGYiQAX-H5mAv&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAQqsmIT08IAwiwyQVmCvcjv4iAQVm_GkZzxlw-LQbUEg&oe=643B715F",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/335983047_573279258069723_6836588266670105209_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=iTxi_8eCTXUAX_pebAG&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBEhvEtuK1v6hu3sac1G_eIP0vzC2RZjI32e79BINq2qQ&oe=641979CC",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/331131472_1288673888701640_2489735646346916098_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=NRXSo77e35MAX9KehyX&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDerK44-rnpZRT49vW6VtQ-X_zv7Yn22IcwY3NEwVbkXA&oe=64182222",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/330816451_504018031677620_3412823518984063268_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=CXS5BN06o_8AX8bqF26&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCd2oCKNWdy4S6yy0YMBtad_NjhnC3Fqy1IYWMJuhQ8-Q&oe=641996A4",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/329772972_6393091744080291_6351496476796375937_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=GfkhyZKsJkkAX-eGXSe&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDGme0hFrqJgWwDYee8cnfh5BEEV02_ZG54Rfm-c7xyEQ&oe=64198AF2",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/329215831_741342364090660_5208438655514701325_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=zG6qO016FeoAX8PIH_f&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAOqIZ6SrdoiJrLsnQ-3I4g0eh9u3CLQ3ysrIvUqgutnA&oe=6419B3F2",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/328666496_899855141055876_4069454416032060434_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=3qZdbqL6Y88AX_NUmRO&_nc_ht=scontent.fchc2-1.fna&oh=00_AfC5ulY2QkpYbOSVhYzHKkm79B_SD4Tm3hGoJQs0q5as7g&oe=641820E6",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/328976646_1609829769455652_3619656261580002255_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=PXBZpKbzuLUAX-jW7mi&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDhGL1zn2q8NmgD2oCxQVyuK9rmcL8vQmBfjTgwpZgcOg&oe=64194698",
    ],
    videos: ["TpiB14ZXTSo", "p_pYhp8-x3w"],
    deal: "10% off",
    views: "100k",

    shop: "Capelli Hair Studio",
    shopImg:
      "https://capellihairdesign.co.nz/wp-content/uploads/2015/04/2015-Capelli-Logo.png",
    follower: "14k",
  },
  {
    id: 5,
    title: "WordPress Training Online Course",
    desc: "Are you looking to begin your career or want to gain more advanced skills? Then this course will help you get one step closer to your professional aspirations and develop your skills and knowledge for a rewarding career. So, what you are waiting for? Enrol today and expand your knowledge with Study Plex. Up to 80% OFF, offer expires soon!",
    location: "1 Guildford Street, Birmingham, B19 2HN, UK",
    open_hours:
      "Mon - Wed: 12pm - 10pm, Thu: 12pm - 11pm, Fri - Sat: 12pm - 12am, Sun: Closed",
    phone: "03 261 6129",
    website: "https://studyplex.org/course/wordpress-training-diploma/",
    img: "https://images.luxuryescapes.com/lux-group/image/upload/f_auto,fl_progressive,q_auto:best,c_fill,g_center,w_750,ar_16:9/s3sunsexal3nhjmdlivf",
    images: [
      "https://s14308.pcdn.co/wp-content/uploads/2020/01/the-best-wordpress-training-courses-beaver-builder-blog.jpeg",
      "https://i.pinimg.com/originals/47/c2/48/47c248881e92463f45874f738366e78a.jpg",
      "https://i.ytimg.com/vi/UmEpcyX1TiM/maxresdefault.jpg",
      "https://kinsta.com/wp-content/uploads/2018/02/what-is-wordpress.jpg",
      "https://www.dreamhost.com/static/16b94698cdc9a663986493354805ac2d/2dc7a/wp_hero_furniture_skew.png",
    ],
    videos: ["-t5WNFPoCCE", "UmEpcyX1TiM"],
    deal: "84% off",
    views: "215k",
    shop: "Study Plex",
    shopImg: "https://studyplex.org/wp-content/uploads/2023/01/study.webp",
    follower: "500k",
  },
  {
    id: 6,
    title: "Interior & Exterior Home Insect Proofing Services",
    img: "https://images.luxuryescapes.com/lux-group/image/upload/f_auto,fl_progressive,q_auto:best,c_fill,g_center,w_750,ar_16:9/nnuhxwpaqhnt2iufko8b",
    images: [
      "https://images.luxuryescapes.com/lux-group/image/upload/f_auto,fl_progressive,q_auto:best/zcrxe5m3eqhwxtvq9vu",
      "https://images.luxuryescapes.com/lux-group/image/upload/f_auto,fl_progressive,q_auto:best/nnuhxwpaqhnt2iufko8b",
      "https://assets.architecturaldigest.in/photos/60083edd08ae763b9ae85437/master/pass/pest-free-pest-control-sanitise-your-home-1366x768.jpg",
      "https://www.beisselwindows.com/wp-content/uploads/2023/01/how-to-bug-proof-your-windows-01.jpg",
      "https://149487750.v2.pressablecdn.com/wp-content/uploads/2019/04/Keep-Bugs-Out-of-Home.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7Tzidsx-Y1fDo8azf--HL3vfUnX_MArd-xQ&usqp=CAU",
    ],
    videos: ["05GHwaE6ocA", "GuSCHdIfkAA"],
    deal: "15% off",
    views: "10k",
    desc: "Keep the bugs out of your home thanks to these fantastic offers from FCS Cleaning Services! Simply pick the option that best describes your property and let the team from FCS Cleaning take care of the rest. For an additional charge, they can even treat your outside furniture and fences. So if you don't want to pull up the welcome mat for the unwelcome creepy crawlies around your home, hit 'Buy Now' and bug-proof your home with today's offer!",
    location: "Waterloo Quay, Pipitea, Wellington 6011",
    website: "http://www.activeclean.co.nz/",
    shop: "Active Clean and Maintenance",
    shopImg: "http://www.activeclean.co.nz/img/acmlogo.png",
    follower: "32k",
  },
  {
    id: 7,
    title: "DIY Kombucha Kits",
    img: "https://images.pexels.com/photos/128865/pexels-photo-128865.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    images: [
      "https://static.standard.co.uk/s3fs-public/thumbnails/image/2019/06/03/09/1thekombuchashop2ndshoot-96.jpg?width=1200&auto=webp&quality=75",
      "https://www.rollingstone.com/wp-content/uploads/2021/03/get-kombucha-kombucha-starter-kit.jpg?w=1018",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHnKbZwNg399nvtCVyfiZWhJ6ZC8XDYC2XbbYV5jPfZXKh3O8a_Q5h3MWPZF-RXCQWZ0I&usqp=CAU",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMw6vzIoqg61NyYYCozokTw2q1HAMz8jmoPPIa-Z3Bzz4hvQW_cSD3R9gHtRHrkhbnwSk&usqp=CAU",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8_lHsLs32QXsAhD8yOxoH9I-9Yl5eVNHMZw&usqp=CAU",
      "https://images.saymedia-content.com/.image/t_share/MTc0NjE5OTk1NzI3NDcyNjMw/keep-calm-and-kombucha-on.jpg",
      "https://static-ssl.businessinsider.com/image/5cc72fe0b14bf438d5273346-1200/the%20kombucha%20shop%20kit.jp2",
      "https://cdn.shopify.com/s/files/1/0168/3816/files/kombucha-kit-ready-to-drink.jpg?17340315631536858072",
      "https://cdn.shopify.com/s/files/1/1808/6849/files/siteslideshow1_2048x.jpg?v=1634776027",
      "https://makebooch.com/wp-content/uploads/2021/07/Kitchentoolz-Kombucha-Starter-Kit-Deluxe-with-Heating-Mat.jpg",
    ],
    videos: [
      "HT8Yjkn_gAE",
      "dQmOhLTSVQc",
      "zfti8GsttUw",
      "GQd0V2L5DXA",
      "ZR20NxD4LTE",
    ],
    deal: "buy 2 get 1",
    views: "375k",
    desc: "Yoghurt Kits or Fermenting Pots, Delivered. Boasting a fantastic combination of a side table and a cosy cat bed, your cat can relax or rest inside the side table by your side whether you are reading, lying on the sofa, or hanging around with your friends on the backyard patio",
    location: "Porirua",
    shop: "Symbiota",
    shopImg:
      "https://cdn.shopify.com/s/files/1/0019/0015/0833/files/logo_500x.png?v=1614746175",
    follower: "24k",
  },
  {
    id: 8,
    title: "Six-Pack Chateau de Montgueret Chenin Blanc",
    img: "https://images.pexels.com/photos/66636/pexels-photo-66636.jpeg?auto=compress&cs=tinysrgb&w=1600",
    images: [
      "https://vineonline.blob.core.windows.net/vineonline/vineonline/media/productimages/easyonthepockets6pack.png",
      "https://main-cdn.grabone.co.nz/goimage/fullsize/d331f599823ef3ce7e57dc2a2599e9a0b3f9c19b.jpg",
      "https://cdn10.phillymag.com/wp-content/uploads/sites/3/2020/04/beer-and-wine-1.jpg",
      "https://vineonline.blob.core.windows.net/vineonline/vineonline/media/images/vol_wineplans_sauvignonblanc_image.png",
      "https://dwellbymichelle.com/wp-content/uploads/2021/02/DWELL-Best-WIne-Clubs-Subscription-Boxes-scaled.jpg",
      "https://wp.inews.co.uk/wp-content/uploads/2019/09/kelsey-chance-tAH2cA_BL5g-unsplash.jpg?resize=640,480&strip=all&quality=90",
      "http://www.bhg.com.au/media/28530/friends-clinking-glasses.jpg",
      "https://images.immediate.co.uk/production/volatile/sites/2/2021/05/cover-Copy-8a0d749.jpg",
      "https://people.com/thmb/2GF7hLHR6VFa0M1OoxBatyAnl6Y=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2)/Primary-Image-Best-Wine-Subscriptions-2944f7b86c9d4bd8b3378f07d558ea0b.jpg",
      "https://prweb.co.nz/wp-content/uploads/2020/07/Affordable-Wines-Drink-Recipe-for-Chilly-Nights.jpg",
    ],
    videos: [
      "GswC1T2Zt8I",
      "McoH3mvAoHU",
      "qFbgLh-n9hU",
      "4D4DbTr3E6U",
      "3mo0RwTUt44",
    ],
    deal: "25% off",
    views: "102k",
    desc: "Chenin Blanc at its voluptuous best! It isn't easy to find good dry Chenin Blanc of this quality at this price. They're so highly regarded in their own country that the best of them rarely make it out internationally!",
    location: "Hibiscus Coast",
    shop: "Vineonline",
    shopImg:
      "https://www.vineonline.co.nz/Custom/Global/Resources/app/Img/vol_logo_black.svg",
    followers: "350k",
  },
];

const endingdata = [
  {
    id: 1,
    title:
      "One-Night Romantic Luxury Lodge Getaway for Two People in a Superior Suite with Spa Bath",
    img: "https://resources.stuff.co.nz/content/dam/images/1/d/7/0/2/o/image.related.StuffLandscapeThreeByTwo.1464x976.1d6yy9.png/1469770466857.jpg",
    desc: "incl. WiFi, Parking, Breakfast, Two-Course Dinner & Bottle of Bubbles - Option for Superior Suite with Hot Tub or Deluxe Suite with Hot Tub",
    location: "Hanmer Springs",
    images: [
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1588551064447-RM99IETIDNGWX9VQC7N9/Cover%2Bpage%2Bwith%2BStamp%2BFinal.jpg?format=2500w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1579483175009-0ASI2035RTXSLLW1J8E5/Galleria+2.jpg?format=2500w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1579483940801-UXGBI4GHOR2ZE93VQDHE/2019_VB_Braemar-526.jpg?format=2500w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1579483630493-WXSE3EURP1Z9D5N2OPCT/lacquer-4467940.jpg?format=2500w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1565756662666-OGOEMP4EOQVQBFAT39QZ/AdobeStock_187478630.jpeg?format=2500w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1579488233797-P6B1BHPDMH1BSSQOR95H/2019_VB_Braemar-455.jpg?format=750w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1579487206733-XCTS63O745TKHWHQ4I4K/wine+bottle+display.jpg?format=750w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1579488353187-KYRAS312S6MOBWXTJEHZ/2019_VB_Braemar-466-2.jpg?format=750w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1579488393843-Q3P3LQNATGNRKFQRVOZZ/Restaurant+dining.jpg?format=750w",
      "https://images.squarespace-cdn.com/content/v1/5d4b788b77a6c6000116f5c9/1569977774355-SOI3NOA1IF1G8FNXUBFI/image-asset.jpeg?format=750w",
    ],
    videos: [
      "TJKWEAI91mE",
      "yrf9fWQN3Tg",
      "9jmjo0g8QgM",
      "gbgLbL-VRnM",
      "ETFTtas1p9E",
    ],
    deal: "30% off",
    views: "123k",
  },
  {
    id: 2,
    title: "Children's Ride-On Police Motorbike",
    img: "https://main-cdn.grabone.co.nz/goimage/fullsize/ee533b53d1d916cf0306caa280f0f6e23e800288.jpg",
    desc: "Pick-up options available from Auckland, Wellington, and Christchurch - see fine print for details.",
    location: "Wellington",
    images: [
      "https://main-cdn.grabone.co.nz/goimage/fullsize/ee533b53d1d916cf0306caa280f0f6e23e800288.jpg",
      "https://i.ebayimg.com/images/g/6OEAAOSwSHlgKKJw/s-l1600.jpg",
      "https://www.therange.co.uk/media/5/9/1628475165_12_2238.jpg",
      "https://cdn.shopify.com/s/files/1/0589/4507/7457/products/RCAR-MBIKE-POLICE-PK-99.jpg?v=1641365925",
      "https://oitek.com.au/wp-content/uploads/2021/03/s-l1600-18.jpg",
      "https://img1.wsimg.com/isteam/ip/b7dc5daa-ebe8-40c0-9c34-f277a1bdc3e8/fb_3549094385203091_1440x1440.jpg/:/rs=w:1200,h:1200",
      "https://cdn11.bigcommerce.com/s-yvpirc28f4/images/stencil/1280x1280/products/49996/175001/image_19324__86167.1637849291.jpg?c=1&imbypass=on",
      "https://i.ytimg.com/vi/MPTla3rGcag/maxresdefault.jpg",
      "https://secure.img1-cg.wfcdn.com/im/73749989/compr-r85/1968/196851391/pirecart-12v-ride-on-police-motorcycle-for-kids-3-to-8-years-old-electric-motorbike-for-boys-girls-with-training-wheels-headlights-siren-sound-music-black.jpg",
      "https://www.dealsdirect.co.nz/christchurch/wp-content/uploads/2022/02/GetPhoto-24-1-600x400.jpg",
    ],
    videos: ["z_ng-RD6tsI", "XwRV604uUx8", "ZrzFJbCCDNQ"],
    deal: "35% off",
    views: "212k",
  },
  {
    id: 3,
    title: "Any Two Single Sized Souvlakis – Option for Four Souvlakis",
    img: "https://main-cdn.grabone.co.nz/goimage/fullsize/7954599574d9ecce86f49cc73f995232feea8c70.jpg",
    desc: "Choose Between Chicken, Lamb, Falafel & Halloumi or The Fresh Special of the Month",
    location: "Christchurch",
    images: [
      "https://www.jocooks.com/wp-content/uploads/2013/07/lamb-souvlaki-1-9.jpg",
      "https://www.unicornsinthekitchen.com/wp-content/uploads/2020/01/Chicken-souvlaki.jpg",
      "https://www.177milkstreet.com/assets/site/Recipes/_large/pork-souvlaki-tzatziki-tomato-onion-salad.jpg",
      "https://itsallgreektome.london/wp-content/uploads/2019/04/souvlaki.jpg",
      "https://lh3.googleusercontent.com/p/AF1QipP-4hmobNCd1D2HsESKfj6cx_0aWbmRyPsDnJ7B=w1080-h608-p-no-v0",
      "https://cdn1.neoskosmos.com/uploads/sites/2/2015/05/souvi.jpg",
      "https://vikalinka.com/wp-content/uploads/2020/06/Chicken-Souvlaki-3.jpg",
      "https://www.willcookforsmiles.com/wp-content/uploads/2022/07/chicken-souvlaki-in-a-pita.jpg",
      "https://silkroadrecipes.com/wp-content/uploads/2021/04/Greek-Souvlaki-Pita-square.jpg",
      "https://www.theseasonedmom.com/wp-content/uploads/2020/03/Chicken-Souvlaki-10.jpg",
    ],
    videos: [
      "_ge2NSRRTA4",
      "vmSnCeOeRO8",
      "wF9y3EmPbqU",
      "yZ7PMaCQIP8",
      "mnOqO5ya5GY",
    ],
    deal: "25% off",
    views: "102k",
  },
  {
    id: 4,
    title:
      "Fiji Pacific Harbour Retreat with All-Inclusive Dining & Direct Access to One of the Island's Longest Beaches",
    img: "https://images.luxuryescapes.com/q_auto:eco,c_fill,g_auto,w_800,ar_16:9/iossnzcsw5ioj0ozb5r.webp",
    desc: "Immerse yourself in this pristine corner of the aquamarine Pacific Ocean between Nadi and Suva, wedged between the resort and its very own little triangle of direct beachfront bliss at The Pearl Resort. Access to one of the island's longest beaches, two inviting lagoon pools and a ring of luxurious palms make this Pacific Harbour retreat a bona fide paradise. Take refuge casting a fishing line off the rocks, kayaking on the tranquil Qaraniqio River, which leads to the glistening South Pacific, or snorkelling off the beachfront.",
    location: "Pacific Harbour, Fiji",
    images: [
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/xoym088h4yectt5pxv.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/nryjv0qaydi4ezy03fg.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/iossnzcsw5ioj0ozb5r.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/gd0ce06daadg16ca7wk.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/nm4vh4vg6wq9hp3wll68.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/dq7t51hq1eoolq66vels.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/w9fx7sdcm6kc8otuygoh.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/1v8dqq30lfmw8pphanj.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/r44q84d7e9k7cit1ojr.webp",
      "https://images.luxuryescapes.com/q_auto:good,c_fill,g_auto,w_1200,ar_16:9/5nmkzovlq9nfdht5eqb.webp",
    ],
    videos: [
      "_hcoYulkpgI",
      "eHCqs9WPzm0",
      "0cEJ3vRWWnE",
      "iDeXfrzpwxk",
      "4HmdT40_LAo",
    ],
    deal: "35% off",
    views: "502k",
  },
  {
    id: 5,
    title: "Personalised Children's Storybook, Can You See Me?",
    img: "https://storybookforme.com.au/media/456162/home_banner.jpg",
    desc: "Grab a personalised children's storybook from Storybookfor.me. A fun, rhyming story that's fun for the smallest sleuth to find the pictures on its pages",
    location: "New Zealand",
    images: [
      "https://storybookforme.com.au/media/456162/home_banner.jpg",
      "https://i.etsystatic.com/12184619/r/il/4c7cb3/1708877985/il_fullxfull.1708877985_7fx3.jpg",
      "https://cdn.notonthehighstreet.com/fs/02/b8/28a7-1711-46c9-8103-98fa1fb9cf1b/original_a-magical-personalised-book-like-no-other.jpg",
      "https://embed-ssl.wistia.com/deliveries/77735e36428a98aac10b62647a1fd6f7f54c7712.webp?image_crop_resized=1280x720",
      "https://cdn.notonthehighstreet.com/fs/92/08/b940-200b-4acc-ab29-691c299a78e1/original_personalised-children-s-story-book.jpg",
      "https://44d2640e500b6a36dfcf-ba0deaf2edd2ed1ff01c0f82f9fb6ea0.ssl.cf3.rackcdn.com/the-adventure-of-name-and-his-her-magic-name-book-personalised-preview-1.jpg",
      "https://justamamma.com/wp-content/uploads/2021/07/Create-your-own-personalised-kids-story-book-South-Africa-1024x819.png",
      "https://i.ebayimg.com/images/g/OcEAAOSw4FhhcXeU/s-l1600.jpg",
      "https://44d2640e500b6a36dfcf-ba0deaf2edd2ed1ff01c0f82f9fb6ea0.ssl.cf3.rackcdn.com/personalised-story-book-for-children-with-childs-name.jpg",
      "https://images.prismic.io/wonderbly/7cc58dfb-5813-497f-b3f4-1b579e3b08da_2021_Evergreen_Homepage_SocialSharing.jpg?auto=format%2Ccompress&dpr=&w=1200&h=&fit=&crop=&q=35&gif-q=90",
    ],
    videos: ["FsxODpHRQHk", "kAo4-2UzgPo", "VTrPGMpDShM"],
    deal: "45% off",
    views: "12k",
  },
  {
    id: 6,
    title:
      "Hair Cut & Style – Option for Global Colour, Retouch or Half Head of Foils - Valid From 10th of March",
    img: "https://static.wixstatic.com/media/2f9290d7fd414a6f8a3be16a6e4a5c13.jpg/v1/fill/w_359,h_235,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/%E3%83%96%E3%83%AD%E3%83%B3%E3%83%89%E3%82%A6%E3%82%A7%E3%83%BC%E3%83%93%E3%83%BC%E3%83%98%E3%82%A2.jpg",
    desc: "Transform your new look at Blink Hair Design, a cool and quirky salon located in Somerfield. The experienced and talented team can cater to all your hair needs from colours and cuts to lush conditioning treatments.",
    location:
      "Chris4a Somerfield Street, Somerfield, Christchurch, New Zealandtchurch",
    images: [
      "https://static.wixstatic.com/media/2f9290d7fd414a6f8a3be16a6e4a5c13.jpg/v1/fill/w_359,h_235,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/%E3%83%96%E3%83%AD%E3%83%B3%E3%83%89%E3%82%A6%E3%82%A7%E3%83%BC%E3%83%93%E3%83%BC%E3%83%98%E3%82%A2.jpg",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/292591047_461438795981889_3721666611982531527_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=e3f864&_nc_ohc=XhV8xWzlFHUAX9hjRwN&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDY3apsNZny8RmWLmTEAV_4OnTJuv4dgSk9KFqGg8sLnw&oe=641B3C33",

      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/291948733_461438799315222_2639846677698876562_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=jyiEk6_k_FIAX8xaeg0&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDDmVXFNDEeFfgBdDydLrzhA4MEMRD537Q0RjQNaxTAMw&oe=641BD2D6",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.18169-9/14264216_1777854322472220_1792416224482752683_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=174925&_nc_ohc=IVsKe972KnkAX8tJqk4&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAMip3HT-jNPX_z8lAHNfs8MBRaW1ag2EK6QvM2LDfnLA&oe=643DFD85",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/314505582_566779008781200_8845615568082724014_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_ohc=bp9ee9jl9Q8AX9pknru&_nc_ht=scontent.fchc2-1.fna&oh=00_AfCKoXsqsrn7TJobL9Fg4q-v4ynYwaC3lGUwzhxLVkNOyg&oe=641BC4E1",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/313390959_565195588939542_4348303432889009708_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=epHaRzeZCqQAX8z8w6s&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDCFlM-MNf8uUGj45MdHpoha9NZdmsskx3nxGt7H-UopQ&oe=641BAB5B",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/314928187_565074548951646_2179242684673057194_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=P7Yggt07XLYAX9cjwTP&_nc_ht=scontent.fchc2-1.fna&oh=00_AfBDGODDSJa5QPuBL159s0ka_56qWoAOZyF8dxNDaFlYWA&oe=641A84A3",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/314591481_562639235861844_6024988972260051127_n.jpg?stp=cp6_dst-jpg&_nc_cat=111&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=KP3IjstbysoAX8KmyeZ&_nc_ht=scontent.fchc2-1.fna&oh=00_AfA6VBp7LIZqd_-DHUQBEA3Nx7hJdpOFj6e0RiIYlxAkaQ&oe=641B9279",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t39.30808-6/312915249_551566823635752_3065928072243777533_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=730e14&_nc_ohc=cmDmqePUi84AX98F-2p&_nc_ht=scontent.fchc2-1.fna&oh=00_AfAh7jw4nU66Bfz3b6Ux-1bESN_o3DJNMcrlZXOfD5NHgQ&oe=641C29B1",
      "https://scontent.fchc2-1.fna.fbcdn.net/v/t1.6435-9/99292778_2625296867727957_7395081819898511360_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=a26aad&_nc_ohc=5XS9XgyQkjgAX8duB4W&_nc_ht=scontent.fchc2-1.fna&oh=00_AfDl5RoETNqGuUqgSJNJiqJqPxtE_3GMYOdJsalW6oHyvw&oe=643DEE62",
    ],
    videos: ["sMEfpSr95pE", "KMb2tqskCSw"],
    deal: "25% off",
    views: "152k",
  },
  {
    id: 7,
    title: "Turning the lights on at the Vatican Museums",
    img: "https://images.contentstack.io/v3/assets/blt06f605a34f1194ff/blt9e1abe1f66448888/63f61cd0e16bc77f58330ac7/Vatican-Museums-2022-(Q2)-Originals----048_Unforgettable_moments_LP-Web_Portrait.jpg",
    desc: "Join the Vatican Museums’ head keykeeper as he unlocks the doors to the historic museums. See the 500-year-old collection before it opens to the public and be awe-struck as you switch on the lights in the Sistine Chapel, having it almost entirely to yourself in this once-in-a-lifetime experience.",
    location: "Vatican",
    images: [
      "https://images.contentstack.io/v3/assets/blt06f605a34f1194ff/blt9e1abe1f66448888/63f61cd0e16bc77f58330ac7/Vatican-Museums-2022-(Q2)-Originals----048_Unforgettable_moments_LP-Web_Portrait.jpg",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/home/01_Musei_del_papa/_jcr_content/renditions/original.jpg",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/home/02_collezioni.jpg/_jcr_content/renditions/cq5dam.web.820h.jpeg",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/home/03_Visita.jpg/_jcr_content/renditions/cq5dam.web.820h.jpeg",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/musei_papa/storia/01_San_Lorenzo_distribuzione_elemosine/_jcr_content/renditions/original.png",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/collezioni/03_aree_archeologiche.jpg/_jcr_content/renditions/cq5dam.web.820h.jpeg",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/musei_papa/storia/03_carte_geografiche_Venezia/_jcr_content/renditions/original.png",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/musei_papa/storia/04_cortile_ottagono/_jcr_content/renditions/original.png",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/musei_papa/storia/05_braccio_nuovo/_jcr_content/renditions/original.png",
      "https://m.museivaticani.va/content/dam/museivaticani/immagini/collezioni/musei/museo_gregoriano_egizio/01_03a_naoforo_vaticano_dettaglio/jcr:content/renditions/original.jpg",
    ],
    videos: ["xg8SVfl40NU", "Q7IbxOmTeSA", "gbQKTmpNmNw"],
    deal: "25% off",
    views: "102k",
  },
  {
    id: 8,
    title:
      "Adult Entry to Minus 5° ICE BAR Queenstown incl. One Cocktail - Options for Two Cocktails and Option for Family Entry",
    img: "https://d3eh3svpl1busq.cloudfront.net/PZExPKxKWRXhqYzyeFuarGXGTYwAIWTv/assets/static/optimized/rev-e9fa620/wp-content/uploads/2017/09/minus5-banner.jpg",
    desc: "Minus 5º ICE BAR features over 18 tonnes of crystal clear ice architecture for you to explore. Try out their ice furniture, prop up their ice bar, and drink their famously delicious cocktails from ice glasses. You'll enjoy every moment of this unique experience – it's the coolest way to begin your night out.",
    location: "Steamer Wharf",
    images: [
      "https://d3eh3svpl1busq.cloudfront.net/PZExPKxKWRXhqYzyeFuarGXGTYwAIWTv/assets/static/optimized/rev-e9fa620/wp-content/uploads/2017/09/minus5-banner.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/EI1A5306.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/EI1A5368.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/MNS5_MFQT_2014_09_25_C1638_8405-1.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/EI1A5363.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/EI1A5356.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/EI1A5310.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/VENUE-HIRE.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/ice-sculpting-logo-e1412733229735.jpg",
      "https://149748505.v2.pressablecdn.com/wp-content/uploads/2014/09/ice-sculpting.jpg",
    ],
    videos: [
      "yvY2JaEepWs",
      "gQmxxA1_p3Y",
      "JnSkH85mgWE",
      "K0zNsPg8xWk",
      "pntSf7R76ew",
    ],
    deal: "75% off",
    views: "125k",
  },
];

export { cannotmissdata, endingdata, bestdeal, TopBar, trendingdata };
