import React, { FC, useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
// import QRCode from "react-qr-code";
import operatorApi from "api/operator/operator.api";
import menuApi from "api/menu/menu.api";
import Loading from "components/Loading/Loading";
import { FaDownload, FaPrint } from "react-icons/fa";
import axios from "axios";
import { baseURL } from "configs/axiosConfig";

import { QRCode } from "react-qrcode-logo";
import {
  ChevronDownIcon,
  ClockIcon,
  MapIcon,
  MapPinIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  PrinterIcon,
  QrCodeIcon,
} from "@heroicons/react/24/outline";
import { MdOutlineArrowCircleDown } from "react-icons/md";

import { setIsCartOpen, setContactInfo } from "../../redux/features/cartState";
import MenuItem from "./menuItem/MenuItem";
import FloatingCartButton from "./menuItem/FloatingCartButon";
import CartMenu from "containers/CartPage/CartMenu";
import { useDispatch } from "react-redux";
import LocationModal from "./menuItem/Location";
import AboutUsModal from "./menuItem/AboutUs";

export interface MenuItem {
  id: string;
  title: string;
  price: number;
  desc: string;
  category: string;
  operator: string;
}

export interface MenuProps {
  className?: string;
}

interface Operator {
  _id: string;
  name?: string;
  email?: string;
  password?: string;
  isAdmin?: boolean;
  coverPhoto?: string;
  photo?: string;
  phone?: string;
  address?: string;
  bio?: string;
  quote?: string;
  createdAt?: string; //
}

interface MenuCategory {
  _id: string;
  name: string;
  image: string;
  operator: string;
}

const Menu: FC<MenuProps> = ({ className = "" }) => {
  const { id } = useParams<{ id: string }>();
  const [operator, setOperator] = useState<Operator[]>([]);
  const [menuCategories, setMenuCategories] = useState<MenuCategory[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const qrRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([getOperator(), getMenuCategories(), getMenuItems()]);
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  const getOperator = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/products/operatorInfoinAuthor?operator=${id}`
      );

      // Ensure operator is an array before mapping
      if (response.data?.success) {
        let operator = response.data.operator;

        if (!Array.isArray(operator)) {
          // If `operator` is an object, wrap it in an array
          operator = operator ? [operator] : [];
        }

        const items: Operator[] = operator.map((item: any) => ({
          _id: item._id,
          name: item.name,
          email: item.email,
          password: item.password,
          isAdmin: item.isAdmin,
          coverPhoto: item.coverPhoto,
          photo: item.photo,
          phone: item.phone,
          address: item.address,
          bio: item.bio,
          quote: item.quote,
          createdAt: item.createdAt,
        }));

        setOperator(items);
        dispatch(setContactInfo(response.data.operator));
      } else {
        console.warn("Unexpected response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching operator data:", error);
    }
  };

  const getMenuCategories = async () => {
    try {
      const response = await menuApi.getMenuCategory({ operator: id });
      if (response.success && response.category) {
        setMenuCategories(response.category);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getMenuItems = async () => {
    try {
      const response = await menuApi.getMenuItems({ operator: id });
      // Ensure menuItems is an array before setting state
      if (response?.success) {
        let menuItems = response.menuItems;

        console.log({ menuItems });

        if (!Array.isArray(menuItems)) {
          // If `menuItem` is an object, wrap it in an array
          menuItems = menuItems ? [menuItems] : [];
        }

        setMenuItems(menuItems);
      } else {
        console.warn("Unexpected response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("QR") as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${operator[0].name}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [expandedAboutus, setExpandedAboutus] = useState(false);

  const handleAboutUsExpandClick = () => {
    setExpandedAboutus(!expandedAboutus);
  };

  const [expandedSeeInMap, setExpandedSeeInMap] = useState(false);

  const handleSeeInMapExpandClick = () => {
    setExpandedSeeInMap(!expandedSeeInMap);
  };

  const downloadCodeMobile = () => {
    const canvas = document.getElementById("QR-mobile") as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${operator[0].name}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const printQRCode = () => {
    window.print();
  };

  // expansion menu
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);

  // Ensure the first category opens by default once menuCategories are available
  useEffect(() => {
    if (menuCategories.length > 0) {
      setExpandedCategory(menuCategories[0]._id);
    }
  }, [menuCategories]);

  const toggleCategory = (categoryId: any) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="h-full w-full ">
      {/* Cover image */}
      <div className="desktop w-full max-w-[1280px] mx-auto">
        <img
          src={operator[0].coverPhoto}
          alt="Operator"
          className="rounded h-[200px] w-full object-cover"
        />
      </div>
      {/* Top section  */}
      <div className="max-w-[1280px] mx-auto flex flex-col md:flex-row p-5">
        {/* Left Section (30%) */}
        <div className="flex flex-col items-center justify-center w-full md:w-[30%] mt-5 cursor-pointer xs:flex-fow">
          <div onClick={() => navigate(`/author/${operator[0]._id}`)}>
            <div className="relative">
              <img
                src={operator[0].photo}
                alt="Operator"
                className="rounded-full border-[2px] border-neutral-200 dark:border-neutral-500 p-2 object-cover w-[200px] h-[200px] sm:w-[200px] sm:h-[200px]"
              />
              {/* Green Dot positioned next to the avatar */}
              <span className="absolute bottom-[10px] right-[30px] w-5 h-5 bg-green-500 border-0 border-white rounded-full" />
            </div>
          </div>
        </div>

        {/* / Right Section (70%)   */}
        <div className="w-full lg:w-[70%] p-0 gap-4 border-0 desktop ">
          <div className="flex flex-row items-center gap-2 justify-end ">
            <div className="desktop w-full">
              <h3 className="text-2xl font-bold text-black capitalize pt-0 sm:pt-3 text-center dark:text-red-500">
                {operator[0].name}
              </h3>
              <p className="text-sm text-black capitalise text-center  dark:text-neutral-200">
                {operator[0].address}
              </p>
              <div className="w-[50%] m-auto mt-2">
                <div className="flex">
                  <button
                    className="flex items-center space-x-2 border p-2 rounded w-[100%] justify-center dark:border-neutral-500"
                    onClick={handleSeeInMapExpandClick}
                  >
                    <MapPinIcon className="text-red-300 text-[10px] max-h-[20px]" />
                    <span className="text-gray-800 text-sm dark:text-neutral-200">
                      See On Map
                    </span>
                  </button>
                  <button
                    className="flex items-center space-x-2 border p-2 rounded w-[100%] justify-center dark:border-neutral-500"
                    onClick={handleAboutUsExpandClick}
                  >
                    <ClockIcon className="text-red-300 text-[10px] max-h-[20px]" />
                    <span className="text-gray-800 text-sm dark:text-neutral-200">
                      About us
                    </span>
                  </button>
                </div>
                <div className="flex">
                  <button
                    className="flex items-center space-x-2 border p-2 rounded w-[100%] justify-center dark:border-neutral-500"
                    onClick={handleExpandClick}
                  >
                    <QrCodeIcon className="text-red-300 text-[10px] max-h-[20px]" />
                    <span className="text-gray-800 text-sm dark:text-neutral-200">
                      QR Download
                    </span>
                  </button>
                  <button
                    className="flex items-center space-x-2 border p-2 rounded w-[100%] justify-center dark:border-neutral-500"
                    onClick={printQRCode}
                  >
                    <PrinterIcon className="text-red-300 text-[10px] max-h-[20px]" />
                    <span className="text-gray-800 text-sm dark:text-neutral-200">
                      Print
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div ref={qrRef} className="self-end ">
              <QRCode
                value={window.location.href}
                logoImage={operator[0].photo}
                logoHeight={50}
                logoWidth={50}
                logoOpacity={1}
                enableCORS={true}
                qrStyle="dots"
                eyeRadius={10}
                id={"QR"}
                size={220}
              />
            </div>
          </div>
        </div>

        {/* Mobile display  */}
        <div className=" mobile w-full sm:w-[70%] p-4 gap-4  gap-2 flex flex-col items-center justify-center">
          <h3 className="text-2xl font-bold text-black capitalize pt-0 sm:pt-3 text-center dark:text-red-500">
            {operator[0].name}
          </h3>
          <p className="text-sm text-black capitalize pt-0 sm:pt-3 text-center dark:text-neutral-200">
            {operator[0].address}
          </p>
          <div className="w-[80%] m-auto mt-5">
            <button
              className="flex items-center space-x-2 border p-2 rounded w-[100%] justify-center dark:border-neutral-500"
              onClick={handleSeeInMapExpandClick}
            >
              <MapPinIcon className="text-red-300 text-[10px] max-h-[20px]" />
              <span className="text-gray-800 text-sm  dark:text-neutral-200">
                See On Map
              </span>
            </button>
            <button
              className="flex items-center space-x-2 border p-2 rounded w-[100%] justify-center dark:border-neutral-500"
              onClick={handleAboutUsExpandClick}
            >
              <ClockIcon className="text-red-300 text-[10px] max-h-[20px]" />
              <span className="text-gray-800 text-sm dark:text-neutral-200">
                About us
              </span>
            </button>
            <button
              className="flex items-center space-x-2 border p-2 rounded w-[100%] justify-center dark:border-neutral-500"
              onClick={handleExpandClick}
            >
              <QrCodeIcon className="text-red-300 text-[10px] max-h-[20px]" />
              <span className="text-gray-800 text-sm  dark:text-neutral-200">
                QR Download
              </span>
            </button>
          </div>
        </div>
        {/* Modal Component */}
        <div
          className={`fixed inset-0 flex items-center justify-center z-50 ${
            expanded ? "block" : "hidden"
          }`}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
        >
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={handleExpandClick}
          ></div>

          <div className="relative p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
            {/* Avatar section */}
            <div className="flex items-center justify-center mt-[-5rem] ">
              <div className="relative">
                <div className="absolute right-0 bottom-0 w-6 h-6 rounded-full bg-green-500 z-10"></div>
                <img
                  src={operator[0].photo}
                  alt="operator avatar"
                  className="w-24 h-24 border-2 border-black object-cover rounded-full backdrop-blur-sm"
                />
              </div>
            </div>

            {/* Name Section */}
            <div className="flex flex-col items-center mt-5">
              <p className="text-xl font-bold uppercase text-center text-black">
                {operator[0].name}
              </p>
            </div>

            {/* QR Code Section */}
            <div className="flex justify-center mt-4">
              <QRCode
                value={window.location.href}
                logoImage={operator[0].photo}
                logoHeight={100}
                logoWidth={100}
                logoOpacity={1}
                enableCORS={true}
                qrStyle="dots"
                eyeRadius={10}
                id="QR-mobile"
                size={300}
              />
            </div>

            {/* Download Button */}
            <button
              type="button"
              onClick={downloadCodeMobile}
              className="mt-4 flex flex-col items-center justify-center bg-gray-300 text-black rounded-sm w-[150px] hover:bg-gray-600 hover:shadow-none"
            >
              QR Download
            </button>
          </div>
        </div>
        {/* Modal Component About Us*/}
        <AboutUsModal
          expandedAboutus={expandedAboutus}
          handleAboutUsExpandClick={handleAboutUsExpandClick}
          operator={operator}
        />
        {/* Modal component See in Map */}
        <LocationModal
          operator={operator}
          expanded={expandedSeeInMap}
          handleExpandClick={handleSeeInMapExpandClick}
        />
      </div>

      {/* Bottom section */}
      <div className="p-0 mx-auto flex-grow bg-transparent max-w-[1280px]">
        <div className="grid gap-1">
          {menuCategories.map((cat) => (
            <div
              key={cat._id}
              className="grid gap-1 mt-0 border-t border-gray-200 dark:border-neutral-500"
            >
              {/* Category Header */}
              <button
                onClick={() => toggleCategory(cat._id)}
                className="w-full flex items-center justify-between bg-transparent sm:flex-col lg:flex-row p-2"
              >
                <div className="w-[50%] flex justify-center items-center">
                  <h4 className="font-bold text-secondary-black text-2xl sm:text-4xl mx-2">
                    {cat.name}
                  </h4>
                </div>
                <MdOutlineArrowCircleDown
                  className={`w-8 h-8 m-2 transition-transform ${
                    expandedCategory === cat._id
                      ? "rotate-180 text-red-200"
                      : "text-red-500"
                  }`}
                />
                <div className="w-[50%] sm:max-w-full max-w-[50%]">
                  <img
                    src={cat.image}
                    alt={cat.name}
                    className="w-full object-cover mx-auto  sm:max-h-[70px] lg:max-h-[150px]"
                  />
                </div>
              </button>

              {/* Expandable Menu Items */}
              <div
                className={`overflow-hidden transition-all ${
                  expandedCategory === cat._id
                    ? "max-h-[1500px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 border-b-0  my-4 sm:my-0 mx-2">
                  {menuItems
                    .filter((item) => item.category === cat._id)
                    .map((item) => (
                      <div className="border-0">
                        <MenuItem item={item} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Floating cart button  */}
      <FloatingCartButton />
    </div>
  );
};

export default Menu;
