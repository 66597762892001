import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Apis from "../../api/order/OrderApi";
import { ErrorAction, tokenProtection } from "../Protection";
import { toast } from "react-toastify";
// import toast from "react-hot-toast";

/**
 * Create Order
 */
export const createOrderAction = createAsyncThunk(
  "order/create",
  async (order, { getState, rejectWithValue, dispatch }) => {
    try {
      const data = await Apis.createOrderService(
        order,
        tokenProtection(getState)
      );
      return data;
    } catch (error) {
      ErrorAction(error, dispatch, "ORDER_CREATE_FAIL");
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Get All Orders
 */
export const getAllOrdersAction = createAsyncThunk(
  "order/getAll",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const data = await Apis.getAllOrdersService();
      return data;
    } catch (error) {
      ErrorAction(error, dispatch, "ORDER_GET_ALL_FAIL");
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Get Order by ID
 */
export const getOrderByIdAction = createAsyncThunk(
  "order/getById",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const data = await Apis.getOrderByIdService(id);
      return data;
    } catch (error) {
      ErrorAction(error, dispatch, "ORDER_GET_BY_ID_FAIL");
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Delete Order
 */
export const deleteOrderAction = createAsyncThunk(
  "order/delete",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      await Apis.deleteOrderByIdService(id);
      toast.success("Order deleted!!");

      // Refresh orders after delete
      const data = await Apis.getAllOrdersService();
      return data;
    } catch (error) {
      ErrorAction(error, dispatch, "ORDER_DELETE_FAIL");
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Delete All Orders
 */
export const deleteAllOrdersAction = createAsyncThunk(
  "order/deleteAll",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      await Apis.deleteAllOrdersService();
      toast.success("All orders deleted!!");
      return true;
    } catch (error) {
      ErrorAction(error, dispatch, "ORDER_DELETE_ALL_FAIL");
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Stripe Checkout
 */
export const stripeCheckoutAction = createAsyncThunk(
  "order/stripeCheckout",
  async (datas, { rejectWithValue, dispatch }) => {
    try {
      console.log(datas);
      const data = await Apis.stripeCheckoutService(datas);
      console.log(data);
      return data?.url;
    } catch (error) {
      ErrorAction(error, dispatch, "STRIPE_ERROR");
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Order Slice
 */
const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    order: null,
    stripeUrl: "",
    loading: false,
    error: null,
    success: false,
  },
  reducers: {
    resetOrderState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.order = null;
      state.stripeUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder

      // Create Order
      .addCase(createOrderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createOrderAction.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload;
        state.success = true;
      })
      .addCase(createOrderAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Get All Orders
      .addCase(getAllOrdersAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllOrdersAction.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(getAllOrdersAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Get Order By ID
      .addCase(getOrderByIdAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrderByIdAction.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload;
      })
      .addCase(getOrderByIdAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Order
      .addCase(deleteOrderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOrderAction.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload; // updated orders after deletion
        state.success = true;
      })
      .addCase(deleteOrderAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete All Orders
      .addCase(deleteAllOrdersAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAllOrdersAction.fulfilled, (state) => {
        state.loading = false;
        state.orders = [];
        state.success = true;
      })
      .addCase(deleteAllOrdersAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Stripe Checkout
      .addCase(stripeCheckoutAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(stripeCheckoutAction.fulfilled, (state, action) => {
        state.loading = false;
        state.stripeUrl = action.payload;
      })
      .addCase(stripeCheckoutAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetOrderState } = orderSlice.actions;

export default orderSlice.reducer;
