import React, { useState } from "react";

const Bio: React.FC<{ bio?: string }> = ({ bio = "" }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="px-5 ">
      <p
        className={`text-lg md:text-md overflow-hidden transition-all duration-300 ${
          expanded ? "" : "line-clamp-2"
        }`}
      >
        {bio}
      </p>

      {bio.length > 100 && (
        <button
          onClick={() => setExpanded(!expanded)}
          className="text-red-300 hover:underline mt-2 text-sm xs:text-xs"
        >
          {expanded ? "Less" : "more"}
        </button>
      )}
    </div>
  );
};

export default Bio;
