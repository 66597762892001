// ModalOrderingMethod.tsx
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { XMarkIcon } from "@heroicons/react/24/outline";

const orderingMethodSchema = Yup.object().shape({
  method: Yup.string().required("Please select an ordering method"),
});

const ModalOrderingMethod = ({ renderChildren, onSubmit }) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  return (
    <>
      {renderChildren({ openModal })}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out transition-opacity"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in transition-opacity"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-white dark:bg-neutral-800 p-6 rounded-lg shadow-xl w-96">
                {/* Close Button */}
                <button className="absolute top-3 right-3" onClick={closeModal}>
                  <XMarkIcon className="w-5 h-5 text-gray-500 hover:text-gray-800" />
                </button>

                <h2 className="text-xl font-semibold mb-4">
                  Select Ordering Method
                </h2>

                <Formik
                  initialValues={{ method: "" }}
                  validationSchema={orderingMethodSchema}
                  onSubmit={(values) => {
                    onSubmit(values.method);
                    closeModal();
                  }}
                >
                  {({ errors, touched }) => (
                    <Form className="space-y-4">
                      {/* Delivery Option */}
                      <label className="flex items-center space-x-2 border rounded-md p-3 cursor-pointer">
                        <Field
                          type="radio"
                          name="method"
                          value="delivery"
                          className="hidden"
                        />
                        <div className="w-5 h-5 border border-gray-400 rounded-full flex items-center justify-center">
                          <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
                        </div>
                        <span className="text-gray-700">Delivery</span>
                      </label>

                      {/* Pickup Option */}
                      <label className="flex items-center space-x-2 border rounded-md p-3 cursor-pointer">
                        <Field
                          type="radio"
                          name="method"
                          value="pickup"
                          className="hidden"
                        />
                        <div className="w-5 h-5 border border-gray-400 rounded-full flex items-center justify-center">
                          <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
                        </div>
                        <span className="text-gray-700">Pickup</span>
                      </label>

                      {/* Error Message */}
                      {errors.method && touched.method && (
                        <p className="text-red-500 text-sm">{errors.method}</p>
                      )}

                      {/* Buttons */}
                      <div className="flex justify-between mt-4">
                        <button
                          type="button"
                          className="text-sm underline text-gray-600"
                          onClick={() => {}}
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          className="bg-blue-500 text-white px-4 py-2 rounded-md"
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalOrderingMethod;
