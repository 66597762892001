import axios from "axios";
import { baseURL } from "../../configs/axiosConfig";
// ************ Public API ************
// create order service
const createOrderService = async (order) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(`${baseURL}/api/orders`, order, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    throw error.response?.data?.message || "Failed to create order";
  }
};

// get all orders
const getAllOrdersService = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(`${baseURL}/api/orders`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// get order by id
const getOrderByIdService = async (id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(`${baseURL}/api/orders/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// delete order by id
const deleteOrderByIdService = async (id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.delete(`${baseURL}/api/orders/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// delete all orders
const deleteAllOrdersService = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.delete(`${baseURL}/api/orders`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// stripe checkout
const stripeCheckoutService = async (datas) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(`${baseURL}/api/orders/checkout`, datas, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export {
  createOrderService,
  getAllOrdersService,
  getOrderByIdService,
  deleteOrderByIdService,
  deleteAllOrdersService,
  stripeCheckoutService,
};
