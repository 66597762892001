import React from "react";

const LoadingWhite: React.FC = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-6">
      <div className="h-[30px] w-[30px] border-[2px] border-neutral-100 rotate-45 rounded-[50%_50%_0%_50%] relative animate-move mb-4">
        <div className="absolute h-[50%] w-[50%] rounded-full border-[2px] border-transparent border-t-neutral-100 border-b-neutral-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-rotate"></div>
      </div>
    </section>
  );
};

export default LoadingWhite;
