import { useSelector, useDispatch } from "react-redux";
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
  emptyCart,
  setContactInfo,
} from "../../redux/features/cartState";
import { useNavigate } from "react-router-dom";
import {
  XCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { baseURL } from "configs/axiosConfig";
import Loading from "../../components/Loading/Loading";
import Icon from "shared/Logo/Iconsmall";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import { createOrderService } from "api/order/OrderApi";
import { toast } from "react-toastify";
import { FaShoppingBag, FaShoppingBasket } from "react-icons/fa";
import ProcessingBar from "shared/Processing Bar/ProcessingBar";
import LoadingWhite from "components/Loading/LoadingWhite";

const CartMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const cart = useSelector((state) => state.cart.cart);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);
  const [isLoading, setIsLoading] = useState(true);
  const [operatorInfo, setOperatorInfo] = useState({});

  //processing process
  const [loading, setLoading] = useState(false);

  const totalPrice = cart.reduce(
    (total, item) => total + item.count * item.price,
    0
  );

  useEffect(() => {
    const fetchOperators = async () => {
      setIsLoading(true);
      const uniqueOperatorIds = [...new Set(cart.map((item) => item.operator))];

      try {
        const operatorData = await Promise.all(
          uniqueOperatorIds.map(async (id) => {
            if (!operatorInfo[id]) {
              const response = await axios.get(
                `${baseURL}/api/products/operatorInfoinAuthor?operator=${id}`
              );
              return { id, data: response.data.operator };
            }
            return null;
          })
        );

        const updatedOperatorInfo = {};
        operatorData.forEach((entry) => {
          if (entry) {
            updatedOperatorInfo[entry.id] = entry.data;
          }
        });

        setOperatorInfo((prev) => ({ ...prev, ...updatedOperatorInfo }));
      } catch (error) {
        console.error("Error fetching operator data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (cart.length > 0) {
      fetchOperators();
    } else {
      setIsLoading(false);
    }
  }, [cart]);

  dispatch(setContactInfo(operatorInfo)); // save all business into state

  // Attach operatorInfo to cart items
  const cartWithOperator = cart.map((item) => ({
    ...item,
    operatorInfo: operatorInfo[item.operator] || null,
  }));

  // Group items by operator
  const groupedItems = cartWithOperator.reduce((acc, item) => {
    if (!acc[item.operator]) acc[item.operator] = [];
    acc[item.operator].push(item);
    return acc;
  }, {});

  // console.log({ cartWithOperator }); // use this item send backend to check out

  const totalPriceByOperator = (cart) =>
    cart.reduce((acc, item) => {
      acc[item.operator] = (acc[item.operator] || 0) + item.price * item.count;
      return acc;
    }, {});

  const totalPrices = totalPriceByOperator(cartWithOperator);

  const placeOrderHandler = async () => {
    if (!user) {
      dispatch(setAuthModalOpen(true));
      return;
    }

    const listofOperators = [
      ...new Set(cartWithOperator.map((item) => item.operator)),
    ];

    try {
      const orderData = {
        user: user?._id,
        operator: listofOperators,
        customer_info: {
          email: user?.email,
          name: user?.name,
          phone: user?.phone,
        },
        orderItems: cartWithOperator?.map((item) => ({
          product: item?._id,
          qty: item?.count,
          name: item?.title,
          operator: item?.operator,
          price: item?.price,
          operatorInfo: item?.operatorInfo,
        })),
        totalPrice,
        subTotalPrice: totalPrice,
      };

      setLoading(true); // Start loading

      const order = await createOrderService(orderData);

      setLoading(false);

      dispatch(emptyCart());
      dispatch(setIsCartOpen(false));

      navigate(`checkout/order/${order?.order?._id}`);
      // Show success message
      toast.success("Order placed successfully!");
      if (order.emailStatus == "success") {
        toast.success("Email sent successfully! Please check your mail");
      } else {
        toast.error("Email sent failed. Contact your administrator");
      }
    } catch (error) {
      console.error("Order placement failed:", error);
      toast.error(error || "Something went wrong. Please try again.");
    } finally {
      setTimeout(() => setLoading(false), 2000); // Hide loader after animation
    }
  };

  const preOperator = useSelector((state) => state.cart.contactInfo);

  return (
    <div
      className={`fixed inset-0 w-full h-full bg-black/40 overflow-y-auto z-[50] auto-scroll ${
        isCartOpen ? "block" : "hidden"
      }`}
      onClick={() => dispatch(setIsCartOpen({}))}
    >
      <div
        className="fixed right-0 bottom-0 w-full max-w-[600px] h-full bg-white dark:bg-black"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-3 overflow-auto h-full pt-13 border">
          {/* HEADER */}
          <div className="flex justify-between items-center ml-1 mt-3 mb-5 relative">
            <Icon className="w-[40px] m-0 p-0" />
            <div className="relative">
              <h4 className="text-xl font-bold text-secondary-main">
                SHOPPING BAG
              </h4>
              {cartWithOperator.length > 0 && (
                <span className="absolute -top-2 -right-5 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-1">
                  {cartWithOperator.length}
                </span>
              )}
            </div>

            <button
              onClick={() => dispatch(setIsCartOpen({}))}
              className="text-gray-500 ml-2 hover:text-red-500 transition-colors w-[30px]"
              aria-label="close"
            >
              <XCircleIcon className="max-w-[30px] text-red-500" />
            </button>
          </div>

          {/* CART LIST */}
          {isLoading ? (
            <Loading />
          ) : (
            <div className="my-2">
              {Object.keys(groupedItems).length === 0 ? (
                <div className="flex flex-col items-center justify-center w-full h-full gap-4">
                  <div className="flex items-center justify-center w-24 h-24 rounded-full border border-main text-main">
                    <FaShoppingBasket className="text-4xl" />
                  </div>
                  <h1 className="text-sm text-center font-light">
                    Your Cart is empty
                  </h1>

                  {preOperator.length > 0 && (
                    <div>
                      <p>go to Previous Business</p>
                      {preOperator.map((operator) => (
                        <div key={operator._id} className="flex items-center">
                          <div className="w-6 h-6 border-2 border-gray-300 rounded-full overflow-hidden">
                            <img
                              src={operator?.photo || ""}
                              alt="Operator Avatar"
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <h5 className="text-sm font-semibold text-red-400 mx-2">
                            {operator.name || "Unknown"}
                          </h5>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {Object.keys(groupedItems).map((operator) => (
                    <div
                      key={operator}
                      className="bg-neutral-100 rounded p-1 dark:bg-neutral-700 border dark:border-neutral-400 mt-2"
                    >
                      <div className="flex justify-between items-center m-2">
                        <div className="flex items-center">
                          <div className="w-6 h-6 border-2 border-gray-300 rounded-full overflow-hidden">
                            <img
                              src={
                                groupedItems[operator][0]?.operatorInfo
                                  ?.photo || ""
                              }
                              alt="Operator Avatar"
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <h5 className="text-sm font-semibold text-red-400 mx-2">
                            {groupedItems[operator][0]?.operatorInfo?.name ||
                              "Unknown"}
                          </h5>
                        </div>
                        <span className="text-primary-contrastText font-bold">
                          ${totalPrices[operator]?.toFixed(2) || "0.00"}
                        </span>
                      </div>

                      {groupedItems[operator].map((item) => (
                        <div
                          key={`${item.title}-${item._id}`}
                          className="border-0 py-1"
                        >
                          <div className="flex justify-between px-1 py-1 items-center">
                            <div className="flex items-center gap-1">
                              <button
                                onClick={() =>
                                  dispatch(decreaseCount({ _id: item._id }))
                                }
                                className="text-gray-600 hover:text-red-500 transition-colors dark:text-neutral-400"
                              >
                                <MinusCircleIcon className="w-5 h-5" />
                              </button>
                              <span className="text-primary-contrastText">
                                {item.count}
                              </span>
                              <button
                                onClick={() =>
                                  dispatch(increaseCount({ _id: item._id }))
                                }
                                className="text-gray-600 hover:text-green-500 transition-colors dark:text-neutral-400"
                              >
                                <PlusCircleIcon className="w-5 h-5" />
                              </button>
                            </div>
                            <span className="text-primary-contrastText text-sm text-left flex-1 ml-2">
                              {item.title}
                            </span>
                            <div className="flex items-center">
                              <button
                                onClick={() =>
                                  dispatch(removeFromCart({ _id: item._id }))
                                }
                                className="text-red-500 ml-2 hover:text-red-600 transition-colors mx-2"
                                aria-label="Remove from cart"
                              >
                                <TrashIcon className="w-4 h-4" />
                              </button>
                              <span className="text-primary-contrastText text-sm">
                                ${item.price * item.count}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}

                  {/* ACTIONS: Sub-total */}
                  <div className="mt-2 bg-neutral-100 dark:bg-neutral-700 dark:border dark:border-neutral-400 rounded">
                    <div className="flex justify-between mx-2 my-1 text-gray-500 text-sm dark:text-neutral-300">
                      <span className="text-primary-contrastText text-sm font-bold">
                        Sub-Total
                      </span>
                      <span className="text-primary-contrastText text-sm font-bold">
                        {isLoading ? "$0" : `${totalPrice.toFixed(2)}`}
                      </span>
                    </div>

                    <div className="flex justify-between mx-2 my-1 text-gray-500 text-sm dark:text-neutral-300">
                      <span>GST (15% included)</span>
                      <span>
                        {isLoading
                          ? "$0"
                          : `${((totalPrice * 3) / 23).toFixed(2)}`}
                      </span>
                    </div>

                    <div className="flex justify-between mx-2 my-1 text-primary-contrastText text-lg font-bold mt-12 dark:border-b">
                      <span>TOTAL</span>
                      <span>
                        {isLoading ? "$0" : `${totalPrice.toFixed(2)}`}
                      </span>
                    </div>

                    <div className="text-black p-3">
                      <div className="w-full flex justify-center gap-2">
                        <button
                          className="rounded-full bg-gray-400 px-6 py-2 text-lg font-semibold border border-gray-500 shadow"
                          onClick={() => dispatch(emptyCart())}
                        >
                          <span className="text-white">CLEAR</span>
                        </button>
                        <button
                          className="rounded-full bg-red-500 px-10 py-2 text-lg font-semibold border border-red-500 shadow text-white"
                          onClick={() => placeOrderHandler()}
                        >
                          {loading ? (
                            <div className="flex items-center">
                              <span className="w-[200px]">
                                Placing Order...
                              </span>
                              <LoadingWhite />
                            </div>
                          ) : user ? (
                            "Place Order"
                          ) : (
                            "Login to Place Order"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        {/* {loading && <ProcessingBar />} */}
      </div>
    </div>
  );
};

export default CartMenu;
