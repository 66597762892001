import React, { FC } from "react";
import { Link } from "react-router-dom";
import GallerySlider from "components/GallerySlider/GallerySlider";

export interface ItemCardProps {
  className?: string;
  title: string;
  galleryImgs: string[];
  id: string;
}

const ItemCard: FC<ItemCardProps> = ({
  className = "",
  title,
  galleryImgs,
  id,
}) => {
  const renderSliderGallery = () => {
    if (
      galleryImgs.length === 0 ||
      !Array.isArray(galleryImgs[0]) ||
      galleryImgs[0].length === 0
    ) {
      return (
        <div className="h-40 flex items-center justify-center">
          No Images Available
        </div>
      );
    }

    const images = galleryImgs[0];

    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`ItemCard_${title.replace(/[^a-zA-Z0-9]/g, "_")}`}
          ratioClass="aspect-w-4 aspect-h-3"
          galleryImgs={images}
          id={id}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="p-4 space-y-2">
        <Link to="/listing-stay-detail" state={{ id }} className="block">
          <h2 className="font-medium text-lg capitalize">
            <span className="line-clamp-1 text-red-500">{title}</span>
          </h2>
        </Link>
      </div>
    );
  };

  return (
    <div
      className={`nc-ItemCard group relative bg-white border border-neutral-100 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="ItemCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default ItemCard;
