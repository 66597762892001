import { useState } from "react";
import { Popover, Transition } from "@headlessui/react";

import {
  RiMicLine,
  RiMessage2Line,
  RiFileLine,
  RiSendPlane2Line,
} from "react-icons/ri";

import {
  CiChat2,
  CiSearch,
  CiHeart,
  CiUser,
  CiShoppingCart,
} from "react-icons/ci";

import { MdOutlineShoppingCartCheckout } from "react-icons/md";

import "./FloatingNav.css";
import { useDispatch, useSelector } from "react-redux";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import Avatar from "shared/Avatar/Avatar";
import { useNavigate } from "react-router-dom";

const FloatingNav = () => {
  const [isOpen, setIsOpen] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="fixed bottom-0 left-0 z-50">
      <input
        type="checkbox"
        id="toggle"
        className="hidden"
        checked={isOpen}
        onChange={() => setIsOpen(!isOpen)}
      />
      <div className="flex justify-start">
        <label
          htmlFor="toggle"
          className={`relative z-50 max-w-[400px] min-w-[60px] w-full h-[60px] m-2 rounded-full cursor-pointer flex justify-start items-center p-6 overflow-hidden transition-all duration-300 ${
            isOpen ? "backdrop-blur-lg bg-black/30 neon-black-box-center" : ""
          }`}
        >
          <span
            className={`absolute w-4 h-[2px] bg-[#ff0000] transition-all duration-300 transform neon-black-box-center ${
              isOpen ? "rotate-90 " : "rotate-[225deg]"
            }`}
          ></span>
          <span
            className={`absolute w-4 h-[2px] bg-[#ff0000] transition-all duration-300 transform neon-black-box-center ${
              isOpen ? "rotate-0" : "rotate-[135deg]"
            }`}
          ></span>
        </label>

        {!isOpen && (
          <nav className="opacity-100 transition-opacity duration-500 bg-[#222] w-full rounded-md px-1 py-2 shadow-lg">
            <ul className="flex justify-between space-x-2">
              <li
                className={`linave animate-itop  border border-red-400 rounded p-1 ${
                  !isOpen ? "delay-300 " : "reverse"
                }`}
                onClick={() => navigate("/")}
              >
                <div className="text-[#ff0000] text-2xl hover:text-white flex flex-col items-center min-w-[50px]">
                  <CiSearch />
                  <p className="text-xs">Explore</p>
                </div>
              </li>
              <li
                className={`linave animate-itop border border-red-400 rounded p-1 ${
                  !isOpen ? " delay-400 " : "delay-400 reverse"
                }`}
                onClick={() => navigate("/")}
              >
                <div className="text-[#ff0000] text-2xl hover:text-white flex flex-col items-center min-w-[50px]">
                  <CiHeart />
                  <p className="text-xs">Wishlists</p>
                </div>
              </li>

              <li
                className={`linave animate-itop border border-red-400 rounded p-1 ${
                  !isOpen ? " delay-500" : "delay-500 reverse"
                }`}
                onClick={() => navigate("/userInbox")}
              >
                <div className="text-[#ff0000] text-2xl hover:text-white flex flex-col items-center min-w-[50px]">
                  <CiChat2 />
                  <p className="text-xs">Message</p>
                </div>
              </li>
              <li
                className={`linave animate-itop border border-red-400 rounded p-1 ${
                  !isOpen ? " delay-600" : "delay-600 reverse"
                }`}
                onClick={() => navigate("/checkout")}
              >
                <div className="text-[#ff0000] text-2xl hover:text-white flex flex-col items-center min-w-[50px]">
                  <CiShoppingCart />
                  <p className="text-xs">Checkout</p>
                </div>
              </li>
              <li
                className={`linave animate-itop border border-red-400 rounded p-1 ${
                  !isOpen ? " delay-600" : "delay-600 reverse"
                }`}
              >
                {user ? (
                  <Avatar
                    sizeClass="w-10 h-10 sm:w-10 sm:h-10 dark:border dark:border-neutral-200"
                    imgUrl={user?.photo || "default-avatar.png"}
                  />
                ) : (
                  <div
                    className="text-[#ff0000] text-2xl hover:text-white flex flex-col items-center min-w-[50px]"
                    onClick={() => dispatch(setAuthModalOpen(true))}
                  >
                    <CiUser />
                    <p className="text-xs">Login</p>
                  </div>
                )}
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default FloatingNav;
