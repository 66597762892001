import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { Empty } from "shared/Notifications/Error";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/Table/Table";
import {
  deleteAllOrdersAction,
  getAllOrdersAction,
  resetOrderState,
} from "../../redux/features/orderSlide";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "containers/AccountPage/CommonLayout";

const ListingOrders = () => {
  const dispatch = useDispatch();

  // Select state from Redux
  const { orders, loading, error, success } = useSelector(
    (state) => state.order
  );

  console.log({ orders });

  // Fetch all orders when the component mounts or after a delete operation succeeds
  useEffect(() => {
    dispatch(getAllOrdersAction());

    // Optionally reset state after re-fetch
    if (success) {
      dispatch(resetOrderState());
    }
  }, [dispatch, success]);

  const deleteAllOrdersHandler = () => {
    if (window.confirm("All orders will be deleted")) {
      dispatch(deleteAllOrdersAction());
    }
  };

  const deleteLoading = false;

  return (
    <CommonLayout>
      <div>
        {loading ? (
          <div className="flex-colo h-96">
            <Loading />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center gap-2">
              <h2 className="text-xl font-bold">Order History</h2>
              {orders?.length > 0 && (
                <ButtonPrimary
                  disabled={deleteLoading}
                  onClick={deleteAllOrdersHandler}
                  className="bg-flash font-medium text-white py-2 px-6 rounded"
                >
                  Delete All
                </ButtonPrimary>
              )}
            </div>

            {orders?.length === 0 ? (
              <div className="flex-colo h-96">
                <Empty text={"No order found"} />
              </div>
            ) : (
              <Table datas={orders} dashboard={false} />
            )}
          </div>
        )}
      </div>
    </CommonLayout>
  );
};

export default ListingOrders;
