// PickupTime.js
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Define validation schema
const validationSchema = Yup.object({
  pickupTime: Yup.string()
    .required("Please select a pickup time")
    .test(
      "is-between",
      "Pickup time must be between 9:00 AM and 5:00 PM",
      (value) => {
        if (!value) return false;
        const selectedTime = new Date(`2023-01-01T${value}:00`); // Convert to Date object
        const startTime = new Date(`2023-01-01T09:00:00`);
        const endTime = new Date(`2023-01-01T17:00:00`);
        return selectedTime >= startTime && selectedTime <= endTime;
      }
    ),
});

const PickupTime = ({ onSubmit }) => {
  const initialValues = {
    pickupTime: "",
  };

  return (
    <div className="w-full max-w-md mx-auto p-4 border border-gray-300 shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        Select Pickup Time
      </h2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSubmit(values.pickupTime); // Send selected time to parent component
        }}
      >
        {({ isValid, dirty }) => (
          <Form>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Pickup Time
              </label>
              <Field
                type="time"
                name="pickupTime"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                min="09:00"
                max="17:00"
                step="900" // 15-minute intervals
              />
              <ErrorMessage
                name="pickupTime"
                component="div"
                className="text-sm text-red-500 mt-1"
              />
            </div>

            <button
              type="submit"
              disabled={!isValid || !dirty}
              className={`w-full py-2 px-4 text-white font-semibold rounded-md ${
                !isValid || !dirty
                  ? "bg-gray-400"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PickupTime;
