import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoEye } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrderAction,
  resetOrderState,
} from "../../redux/features/orderSlide";
import { toast } from "react-toastify";
import { format } from "timeago.js";
import { dateFormat } from "../../context/Functionalty";

function Table({ dashboard, datas }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log({ datas });
  // Styles
  const Head = "text-xs font-semibold px-6 py-2 uppercase";
  const Text = "px-5 text-sm py-3 leading-6 whitespace-nowrap";
  const badge = "py-1 px-2 rounded-full text-xs font-semibold";

  // Selectors from order slice
  const {
    loading,
    success,
    error,
    deleteSuccess, // if added in your orderSlice initialState
    orders,
  } = useSelector((state) => state.order);

  // Delete handler
  const deleteHandler = (id) => {
    if (window.confirm("This order will be deleted")) {
      dispatch(deleteOrderAction(id));
    }
  };

  // Handle errors
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(resetOrderState());
    }
  }, [error, dispatch]);

  // Handle delete success (optional, if you need it to reload orders)
  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Order deleted successfully!");
      dispatch(resetOrderState());
    }
  }, [deleteSuccess, dispatch]);

  // Row component for table rows
  const Rows = ({ order }) => {
    return (
      <tr>
        <td className={`${Text} font-medium`}>{order?._id?.slice(6, 15)}</td>
        <td className={`${Text} text-center`}>{`${dateFormat(
          order?.createdAt
        )} (${format(order?.createdAt)})`}</td>
        <td className={`${Text} text-center`}>
          {order?.payments?.paymentMethod || "---"}
        </td>
        <td className={`${Text} text-center`}>
          {order?.payments?.status === "completed" && (
            <span className={`${badge} text-green-600 bg-green-100`}>
              {order?.payments?.status}
            </span>
          )}
          {order?.payments?.status === "pending" && (
            <span className={`${badge} text-orange-600 bg-orange-100`}>
              {order?.payments?.status}
            </span>
          )}
          {order?.payments?.status === "cancelled" && (
            <span className={`${badge} text-red-600 bg-red-100`}>
              {order?.payments?.status}
            </span>
          )}
        </td>
        <td className={`${Text} font-bold text-center`}>
          ${order?.totalPrice.toFixed(1)}
        </td>
        <td className={`${Text} float-right flex gap-2`}>
          {!dashboard && (
            <button
              onClick={() => deleteHandler(order?._id)}
              className="border text-red-600 border-red-600 rounded flex-colo items-center w-6 h-6"
            >
              <RiDeleteBinLine />
            </button>
          )}
          <button
            onClick={() => navigate(`/checkout/order/${order?._id}`)}
            className="border text-main border-main rounded flex-colo w-6 h-6"
          >
            <GoEye />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="w-full relative overflow-hidden overflow-x-auto bg-white dark:bg-neutral-800 transition-colors duration-300 scrollbar-thin scrollbar-thumb-gray-400 dark:scrollbar-thumb-neutral-600 scrollbar-track-gray-100 dark:scrollbar-track-neutral-700">
      <table className="table-auto min-w-full border border-gray-300 divide-y divide-gray-200 dark:border-neutral-700 dark:divide-neutral-600 transition-colors duration-300">
        <thead>
          <tr className="bg-gray-100 dark:bg-red-500">
            <th scope="col" className={`${Head} text-left`}>
              ID
            </th>
            <th scope="col" className={`${Head} text-center`}>
              Date
            </th>
            <th scope="col" className={`${Head} text-center`}>
              Method
            </th>
            <th scope="col" className={`${Head} text-center`}>
              Payment Status
            </th>
            <th scope="col" className={`${Head} text-center`}>
              Total
            </th>
            <th scope="col" className={`${Head} text-end`}>
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-600 dark:bg-neutral-800">
          {dashboard
            ? datas?.orders
                ?.slice(0, 5)
                .map((order) => <Rows key={order?._id} order={order} />)
            : datas?.orders?.map((order) => (
                <Rows key={order?._id} order={order} />
              ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
