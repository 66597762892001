import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "configs/axiosConfig";
import Loading from "components/Loading/Loading";
import {
  XCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
  emptyCart,
} from "../../../redux/features/cartState";
import { Formik } from "formik";
import * as yup from "yup";
import Shipping from "./Shipping";
import ContactForm from "./ContactForm";
import OrderingMethod from "./OrderingMethod";
import PickupTime from "./PickupTime";
import ModalOrderingMethod from "./Modal/ModalOrderingMethod";
import { getOrderByIdService } from "api/order/OrderApi";
import { dateFormat } from "../../../context/Functionalty";
import { toast } from "react-toastify";
import { stripeCheckoutAction } from "../../../redux/features/orderSlide";

const MenuCheckOutPagePageMain = ({ className = "" }) => {
  const params = useParams(); // Whole params object
  // console.log({ params });

  const [order, setOrder] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getOrderByIdAction = async () => {
      try {
        const id = params?.orderId;

        const data = await getOrderByIdService(id);

        setOrder(data);
      } catch (error) {
        console.error("Failed to fetch order:", error);
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };

    getOrderByIdAction();
  }, [params]);

  console.log({ order });

  const allOperators = order?.order?.operator;

  const groupedItems = useMemo(() => {
    if (!order?.order?.orderItems) return {};

    const groups = {};

    order.order.orderItems.forEach((item) => {
      const operatorId = item.operator;

      if (!groups[operatorId]) {
        groups[operatorId] = [];
      }

      groups[operatorId].push(item);
    });

    return groups;
  }, [order]);

  //---------------------------------------------------------------------

  const [startDate, setStartDate] = useState(new Date("2023-02-06"));
  const [endDate, setEndDate] = useState(new Date("2023-02-23"));

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart.cart);

  const [isLoading, setIsLoading] = useState(true);
  const [operatorInfo, setOperatorInfo] = useState({});

  const totalPrice = cart.reduce(
    (total, item) => total + item.count * item.price,
    0
  );

  const [guests, setGuests] = useState({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const [showContactForm, setShowContactForm] = useState(false);

  const [contactInfo, setContactInfo] = useState(null); // Store contact form data

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
  };

  const handleContactFormSubmit = (formData) => {
    setContactInfo(formData); // Update state with submitted form data
    setShowContactForm(false); // Optionally hide form after submission
  };

  const [orderMethod, setOrderMethod] = useState(null); // State to store the selected order method
  const [orderingMethod, setOrderingMethod] = useState("");

  const handleOrderingMethodSubmit = (formData) => {
    setOrderMethod(formData.orderMethod); // Update state with the selected order method
  };

  const [pickupTime, setPickupTime] = useState(null); // Store selected pickup time

  const handlePickupTimeSubmit = (time) => {
    setPickupTime(time); // Update state with selected pickup time
  };

  const shipping = order?.order?.shippingAddress;
  const payments = order?.order?.payments;
  const user = order?.order?.user;

  const orderItems = order?.orderItems;
  const paymentsStatus = {
    pending: order?.order?.payments?.status === "pending",
    complete: order?.order?.payments?.status === "completed",
    cancelled: order?.order?.payments?.status === "cancelled",
  };
  const delivery = order?.delivery;

  const Totals = [
    {
      name: "Subtotal",
      cost: `$${order?.order?.subTotalPrice ? order?.order?.subTotalPrice : 0}`,
    },
    {
      name: "Tax",
      cost: `$${order?.order?.taxPrice ? order?.order?.taxPrice : 0}`,
    },
    {
      name: "Shipping",
      cost: `$${shipping?.shippingCost ? shipping?.shippingCost : 0}`,
    },
  ];

  const paymentInfo = [
    {
      title: "Paid Status",
      value: payments?.status,
      bg: true,
    },
    {
      title: "Payment Method",
      value: payments?.paymentMethod,
      bg: false,
    },
    {
      title: "Paid At",
      value: payments?.paymentDate ? dateFormat(payments?.paymentDate) : "---",
      bg: false,
    },
  ];

  const deliveryInfo = [
    {
      title: "Delivery Status",
      value: delivery?.status,
      bg: true,
    },
    {
      title: "Delivery Method",
      value: delivery?.deliveryMethod,
      bg: false,
    },
    {
      title: "Delivery Date",
      value: delivery?.deliveryDate
        ? dateFormat(delivery?.deliveryDate)
        : "---",
      bg: false,
    },
  ];

  const shippingInfo = [
    {
      title: "Customer's Name",
      value: shipping?.fullName,
    },
    {
      title: "Phone Number",
      value: shipping?.phoneNumber,
    },
    {
      title: "Email Address",
      value: shipping?.email,
    },
    {
      title: "Location",
      value: shipping?.location,
    },
    {
      title: "Address",
      value: shipping?.address,
    },
    {
      title: "Shipping Method",
      value: shipping?.shippingMethod,
    },
    {
      title: "Shipping Cost",
      value: `$${shipping?.shippingCost}`,
    },
  ];

  const userInfos = [
    {
      title: "Customer's Name",
      value: user?.displayName,
    },
    {
      title: "Customer's Email",
      value: user?.username,
    },
    {
      title: "Customer's Phone Number",
      value: user?.phone,
    },
  ];

  const handlePayment = () => {
    dispatch(
      stripeCheckoutAction({
        id: order?.order?._id,
        orderItems: order?.order?.orderItems,
        email: user?.username,
      })
    )
      .unwrap()
      .then((url) => {
        console.log("Stripe Checkout URL:", url);
        window.location.href = url;
      })
      .catch((error) => {
        console.error("Payment Error:", error);
      });
  };

  const handleDownload = () => {
    toast.error("Not emplemented yet");
  };

  const renderSidebar = () => {
    return (
      <>
        <div className="flex flex-col gap-8 mb-6">
          {Totals.map((t, i) => (
            <div
              key={i}
              className="flex items-center justify-between text-sm w-full font-semibold"
            >
              {t.name}
              <span className="font-bold">{t.cost}</span>
            </div>
          ))}
          <div className="p-1 pl-4 items-center text-gray-800 bg-dryGray border rounded w-full flex justify-between">
            <h2 className="font-semibold text-sm dark:text-white">Total</h2>
            <button
              type="button"
              className="px-8 bg-red-100 py-4 font-bold flex-colo text-sm rounded"
            >
              ${order?.order?.totalPrice}
            </button>
          </div>
        </div>
        <>
          {paymentsStatus.pending && (
            <>
              <ButtonPrimary
                onClick={handlePayment}
                className="bg-main py-4 w-full rounded text-white flex-colo"
              >
                Purchase Order
              </ButtonPrimary>
            </>
          )}
          {paymentsStatus.complete && (
            <div className="grid sm:grid-cols-2 gap-6 text-sm">
              <Link
                to="/shop"
                className="border border-main py-4 rounded flex-colo"
              >
                Shop Again
              </Link>
              <ButtonPrimary
                onClick={handleDownload}
                className="bg-main py-4 rounded text-white flex-colo"
              >
                Download Invoice
              </ButtonPrimary>
            </div>
          )}
          {(paymentsStatus?.cancelled || shipping?.status === "cancelled") && (
            <>
              <p className="text-red-600 text-sm text-center">
                {shipping?.status === "cancelled"
                  ? "Order has been canceled."
                  : "Payment has been canceled."}{" "}
                {"  "}
                if you have any question please contact us
              </p>
              <Link
                to="/contact-us"
                className="bg-white py-4 rounded text-main mt-4 border flex-colo"
              >
                Contact Us
              </Link>
            </>
          )}
        </>
      </>
    );
  };

  const renderMain = () => {
    const classNames = "text-xs bg-opacity-20 py-1.5 px-4 rounded-full ml-3";
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="w-full space-y-8">
          <div>
            <h3 className="font-semibold text-lg">
              <>
                <span>Your Order</span>{" "}
                {paymentsStatus?.pending ? (
                  <span
                    className={`text-star bg-yellow-400 dark:bg-yellow-400 text-yellow-600 ${classNames}`}
                  >
                    Pending
                  </span>
                ) : paymentsStatus?.complete ? (
                  <span className={`text-green-600 bg-green-600 ${classNames}`}>
                    Complete
                  </span>
                ) : paymentsStatus?.cancelled ? (
                  <span className={`text-red-600 bg-red-600 ${classNames}`}>
                    Cancelled
                  </span>
                ) : (
                  <span className="text-gray-500">Unknown Status</span>
                )}
              </>
            </h3>

            {loading ? (
              <Loading />
            ) : (
              <div className="my-2">
                {!allOperators || allOperators.length === 0 ? (
                  <h4 className="text-center text-xl">Cart is Empty</h4>
                ) : (
                  allOperators.map((operator) => {
                    const items = groupedItems[operator._id] || [];
                    return (
                      <div key={operator._id} className="rounded p-1">
                        <div className="flex items-center mt-6">
                          <div className="flex-1 flex flex-col justify-between sm:flex-row sm:items-center ">
                            <div className="flex flex-row items-center">
                              <div className="flex-shrink-0 w-20 sm:w-10 md:w-12 lg:w-20">
                                <div className="aspect-w-1 aspect-h-1 rounded-full overflow-hidden border-2 shadow border-red-500">
                                  <img
                                    alt=""
                                    className="absolute inset-0 object-cover w-full h-full"
                                    src={operator?.photo || ""}
                                  />
                                </div>
                              </div>

                              <div className="py-5 px-5 space-y-3">
                                <div>
                                  <span className="text-base font-medium mt-1 block">
                                    {operator?.name || "Unknown"}
                                  </span>
                                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                    {operator?.email || "Unknown"}
                                  </span>
                                </div>

                                <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
                                <StartRating />
                              </div>
                            </div>

                            <div className="flex basis-[30px]  justify-end items-start border-b border-neutral-300 dark:border-neutral-700 sm:basis-[80px]">
                              <span className="text-primary-contrastText font-bold ">
                                $
                                {items
                                  .reduce(
                                    (acc, item) => acc + item.price * item.qty,
                                    0
                                  )
                                  .toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* List items */}
                        {items.length === 0 ? (
                          <p>No items for this operator.</p>
                        ) : (
                          items.map((item) => (
                            <div key={item._id} className="border-0 py-1">
                              <div className="flex justify-between px-1 py-1 items-center">
                                <span className="text-primary-contrastText text-sm ml-2">
                                  {item.name} x <strong>{item.qty}</strong>
                                </span>
                                <div className="flex items-center">
                                  <span className="text-primary-contrastText">
                                    ${(item.price * item.qty).toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            )}

            {/* ------------------------------------------------- */}

            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View Totals
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Order details"
            />
          </div>
          {/* <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              renderChildren={({ openModal }) => (
                <button
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString([startDate, endDate])}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            /> 

           <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                          (guests.guestAdults || 0) +
                          (guests.guestChildren || 0)
                        } Guests, ${guests.guestInfants || 0} Infants`}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            /> 
            </div> */}

          {/* user */}
          <div className="bg-dryGray border p-8 rounded dark:border-neutral-400">
            <h2 className="text-md font-semibold mb-4">User Infomation</h2>
            {userInfos?.map((item, index) => (
              <div
                key={index}
                className="grid grid-cols-2 gap-2 py-5 px-2 border-t border-gray-200 dark:border-neutral-400"
              >
                <h5 className="text-sm dark:text-neutral-400">{item.title}</h5>
                <p className={`text-xs text-gray-500 dark:text-neutral-300`}>
                  {item?.value ? item.value : "---"}
                </p>
              </div>
            ))}
          </div>

          {/* payment info */}
          <div className="bg-dryGray border p-8 rounded">
            <h2 className="text-md font-semibold mb-4">Payment Infomation</h2>
            {paymentInfo?.map((item, index) => (
              <div
                key={index}
                className="grid grid-cols-2 gap-2 py-5 px-2 border-t border-gray-200"
              >
                <h5 className="text-sm dark:text-neutral-400">{item.title}</h5>
                <p
                  className={`text-xs capitalize ${
                    item.bg
                      ? item.value === "completed"
                        ? " text-green-600"
                        : (item.value === "awaiting") |
                          (item.value === "pending")
                        ? " text-orange-500"
                        : " text-red-600"
                      : " text-gray-500"
                  }`}
                >
                  {item?.value ? item.value : "---"}
                </p>
              </div>
            ))}
          </div>

          {paymentsStatus.complete && (
            <>
              {/* delivery Info */}
              <div className="bg-dryGray border p-8 rounded">
                <h2 className="text-md font-semibold mb-4">
                  Delivery Infomation
                </h2>
                {deliveryInfo?.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-2 gap-2 py-5 px-2 border-t border-gray-200"
                  >
                    <h5 className="text-sm">{item.title}</h5>
                    <p
                      className={`text-xs capitalize ${
                        item.bg
                          ? item.value === "completed"
                            ? " text-green-600"
                            : (item.value === "awaiting") |
                              (item.value === "pending")
                            ? " text-orange-500"
                            : item.value === "shipped"
                            ? " text-blue-600"
                            : " text-red-600"
                          : " text-gray-500"
                      }`}
                    >
                      {item?.value ? item.value : "---"}
                    </p>
                  </div>
                ))}
              </div>

              {/* shipping info */}
              <div className="bg-dryGray p-8 rounded">
                <h2 className="text-md font-semibold mb-4">Shipping Info</h2>

                {shippingInfo.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-2 gap-2 py-5 px-2 border-t border-gray-200"
                  >
                    <h5 className="text-sm">{item.title}</h5>
                    <p className={`text-xs text-gray-500`}>
                      {item?.value ? item.value : "---"}
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Pay with</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Paypal
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">Credit card</span>
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input defaultValue="111 112 222 999" />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input defaultValue="JOHN DOE" />
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary href={"/pay-done"}>Confirm and pay</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container grid xl:grid-cols-[3fr_2fr] gap-6 w-full items-start">
      <div className="w-full space-y-0">{renderMain()}</div>
      <div className="w-full sticky top-28 xl:mt-4 border-[0.5px] border-red-500 rounded-md sm:p-12 p-4">
        {renderSidebar()}
      </div>
    </div>
  );
};

export default MenuCheckOutPagePageMain;
